import '../../styles/Buttons.css';
import '../../styles/Raffles/Cards.css';

import React, { useState } from 'react';
import { burnTickets, getUser } from '../../server/Firebase';
import { user } from "../../server/Firebase";

import ticketsIcon from '../../assets/raffles/ticket-icon.svg';
import flameIcon from '../../assets/raffles/flame.svg';


export default function BurnModal(props) {

  const [burnAmount, setBurnAmount] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const [userTickets, setUserTickets] = useState(0);

  // Burn Confirmation
  const [burnConfirmation, setBurnConfirmation] = useState(false);

  // Entered Message
  const [showTicketMessage, setShowTicketMessage] = useState("");

  async function burnUserTickets() {
    console.log("Trying to burn user tickets...");
    if (burnAmount > 0) {
      if (totalCost <= props.Balance) {
        var ticketCount = 0;

        for (let i = 0; i < user.tickets?.length; i++) {
          if (user.tickets[i].item === props.Item) {
            ticketCount += user.tickets[i].size;
          }
        }

        setUserTickets(ticketCount);
        if (ticketCount >= props.MaxAmount) {
          return alert(
            "You have reached the maximum amount of tickets allowed!"
          );
        } else if (ticketCount + burnAmount <= props.MaxAmount) {
          setBurnConfirmation(true);

          await burnTickets(
            props.Item,
            props.User.address,
            burnAmount * props.ItemPrice,
            props.Balance,
            burnAmount
          );
          const userUpdate = await getUser(props.User.address);

          let itemAddedEmbed = {
            content: `<@${userUpdate.discordUserId}> 🔥 🎟️`,
            embeds: [
              {
                type: "rich",
                title: `🏪 Tickets Burned`,
                description: `<@${userUpdate.discordUserId}> just burned \`${totalCost}\` tickets for \`${burnAmount}\` entrie(s) into the ${props.Item} raffle!`,
                color: 0xdf4b4b,
                url: `https://www.dskdao.com`,
                thumbnail: {
                  url: props.Image,
                  proxy_url: props.Image,
                  height: 250,
                  width: 250,
                },
              },
            ],
          };

          await fetch(
            process.env.REACT_APP_WEBHOOK,
            {
              method: "POST",
              body: JSON.stringify(itemAddedEmbed),
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then(() => {
              console.log("Burn successful!");
            })
            .catch((err) => {
              console.log(err);
            });

          setShowTicketMessage(
            `You entered this raffle with ${burnAmount} ticket(s). Goodluck! `
          );

          props.SetUserBalance(userUpdate.balance);
          props.SetUserVouchers(userUpdate.tickets);
          setBurnConfirmation(false);
        } else
          return alert(
            `You're trying to enter with ${ticketCount + burnAmount} tickets. Max allowed is ${props.MaxAmount}!`
          );
      } else return alert("Balance was insufficient tickets for transaction.");
    } else return alert("You must burn more than 0 tickets.");
  }

  async function increaseAmount() {
    const currentAmount = burnAmount + 1;
    if (currentAmount <= props.MaxAmount) {
      setBurnAmount(currentAmount);

      const math = Number(currentAmount * props.ItemPrice);
      setTotalCost(math);
    }
  }

  async function decreaseAmount() {
    if (burnAmount <= 0) return;
    const currentAmount = burnAmount - 1;
    setBurnAmount(currentAmount);

    const math = Number(currentAmount * props.ItemPrice);
    setTotalCost(math);
  }

  return (
    <div>
      {burnConfirmation === false && (
        <>
          <div className="burn_modal">
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "grid",
                  alignContent: "center",
                }}
              >
                <div className="item_modal">
                  <img
                    className="item_image"
                    alt=""
                    width="100%"
                    src={props.Image}
                  ></img>
                  <div className="item_title">{props.Item}</div>
                </div>

                <div>
                  <div
                    style={{
                      padding: 10,
                      textAlign: "center",
                      color: "white",
                      fontFamily: "MSBee",
                      textTransform: "uppercase",
                    }}
                  >
                    Select Ticket Amount
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      maxWidth: 250,
                      background: "#414141",
                      padding: 10,
                      color: "white",
                    }}
                  >
                    <button
                      onClick={() => decreaseAmount()}
                      className="basic_home_button"
                      style={{
                        minWidth: 50,
                        maxWidth: 50,
                        marginRight: "auto",
                        marginLeft: "10px",
                        zIndex: 36,
                      }}
                    >
                      -
                    </button>
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        alignContent: "center",
                        background: "#df4b4b",
                        width: 50,
                        height: 50,
                        fontSize: 22,
                        fontFamily: "MSBee",
                      }}
                    >
                      {burnAmount}
                    </div>
                    <button
                      onClick={() => increaseAmount()}
                      className="basic_home_button"
                      style={{
                        minWidth: 50,
                        maxWidth: 50,
                        marginLeft: "auto",
                        marginRight: "10px",
                        zIndex: 36,
                      }}
                    >
                      +
                    </button>
                  </div>
                  <div
                    style={{
                      display: "grid",
                      justifyContent: "center",
                      textAlign: "center",
                      fontSize: 16,
                      fontFamily: "monospace",
                      zIndex: 36,
                    }}
                  >
                    <div
                      style={{
                        padding: 15,
                        textAlign: "center",
                        fontFamily: "MSBee",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      You're burning {totalCost}
                      <img
                        style={{
                          marginLeft: 5,
                          marginRight: 5,
                        }}
                        alt=""
                        height={15}
                        src={ticketsIcon}
                      ></img>{" "}
                      for {burnAmount} entry(s).
                    </div>

                    <button
                      // disabled={true}
                      onClick={() => burnUserTickets()}
                      className="basic_home_button"
                      style={{
                        height: 50,
                        // background: "red",
                      }}
                    >
                      Burn To Enter
                    </button>

                    <button
                      style={{
                        height: 25,
                        width: 25,
                        maxWidth: 25,
                        background: "red",
                        marginTop: 15,
                      }}
                      onClick={() => props.setBurn(false)}
                      className="basic_home_button"
                    >
                      X
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {burnConfirmation === true && (
        <>
          <div
            style={{
              position: "fixed",
              width: "250px",
              height: "250px",
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: "auto",
              marginLeft: "auto",
              overflow: "hidden",
              background: "#313131",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              dispaly: "grid",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "white",
              textTransform: "uppercase",
              borderRadius: 15,
              border: "2px solid white",
              boxShadow: "0px 0px 4rem #df4b4b",
              zIndex: 15000,
            }}
          >
            <div
              style={{
                padding: 15,
                display: "grid",
                height: "100%",
                maxHeight: "100%",
                fontSize: 22,
              }}
            >
              BURNING TICKETS
              <img
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src={flameIcon}
                height={100}
                alt=""
              ></img>
            </div>
          </div>
        </>
      )}

      {showTicketMessage && burnConfirmation === false && (
        <div
          style={{
            position: "fixed",
            width: "250px",
            height: "250px",
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "auto",
            marginLeft: "auto",
            overflow: "hidden",
            background: "#313131",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            dispaly: "grid",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",
            color: "white",
            textTransform: "uppercase",
            borderRadius: 15,
            border: "2px solid white",
            boxShadow: "0px 0px 4rem #df4b4b",
            zIndex: 20000,
          }}
        >
          <div
            style={{
              padding: 3,
              display: "grid",
              justifyContent: "center",
              alignContent: "center",
              height: "100%",
              maxHeight: "100%",
              fontSize: 22,
              zIndex: 25000,
            }}
          >
            {showTicketMessage}
            <div>
              <button
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 20,
                }}
                className="general_buttons"
                onClick={() => props.setModalBurn(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
