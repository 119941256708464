import '../../styles/AddProjects.css';

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';

import { addProject, readProjects, deleteProject } from "../../server/Firebase.js";

export default function AddProject() {
  const {state} = useLocation();
  const [projects, setProjects] = useState([]);
  const [contract, setContract] = useState("");
  const [discord, setDiscord] = useState("");
  const [name, setName] = useState("");
  const [review, setReview] = useState("");
  const [supply, setSupply] = useState(0);
  const [website, setWebsite] = useState("");
  const [wldate, setWldate] = useState("");
  const [wleth, setWlEth] = useState("");
  const [wlusd, setWlUsd] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [mintAmount, setMintAmount] = useState("");
  const [wlAmount, setWlAmount] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [dayFormat, setDayFormat] = useState("");
  const [submit, setSubmit] = useState(false);

  async function checkDetails() {
    if(!contract) return alert("Contract is empty!");
    if(!discord) return alert("Discord is empty!");
    if(!name) return alert("Name is empty!");
    if(!review) return alert("Review is empty!");
    if(!supply) return alert("Supply is empty! ");
    if(!website) return alert("Website is empty!");
    if(!wldate) return alert("Whitelist date is empty!");
    if(!wleth) return alert("Whitelist ETH is empty!");
    if(!wlusd) return alert("Whitelist USD is empty!");
    if(!day) return alert("Date is empty!");
    if(!month) return alert("Date is empty!");
    if(!mintAmount) return alert("Mint amount is empty!");
    if(!wlAmount) return alert("Whitelist amount is empty!");
    if(!timestamp) return alert("Date is empty!");
    return true;
  }

  async function addProjectItem() {
    const check = await checkDetails()
    if(check === true) {
      const addItem = await addProject(contract, discord, name, review, supply, website, wldate, wleth, wlusd, day, month, mintAmount, wlAmount, timestamp, dayFormat)

      alert('Added Item! ID:' + addItem.id)

      setProjects("")
      setContract("")
      setDiscord("")
      setName("")
      setReview("")
      setSupply("")
      setWebsite("")
      setWldate("")
      setWlEth("")
      setWlUsd("")
      setDay("")
      setMonth("")
      setMintAmount("")
      setWlAmount("")
      setTimestamp("")
      setDayFormat("")
      setSubmit(!submit)

      fetchProjects()
    }
  }

  async function deleteProjectById(id) {
    await deleteProject(id)
    fetchProjects()
  }

  function setProjectDate(date) {
    const month = moment(date).format('MMMM');
    const day = moment(date).format('D');
    const dayFormat = moment(date).format('Do');
    const dateFormatted = moment(date).format('L');
    const dateTimestamp = moment(date).format();

    setDay(day);
    setMonth(month);
    setWldate(dateFormatted);
    setTimestamp(dateTimestamp);
    setDayFormat(dayFormat);
  }
  
  async function fetchProjects() {
    const projects = await readProjects()
    
    let revisedArray = [];
    for(let i=0; i<projects.length; i++) {

      const databaseEntry = projects[i].project;

      const projectDetails = { 
        name: databaseEntry.name.stringValue,
        contract: databaseEntry.contract.stringValue,
        wlAmount: databaseEntry.wlAmount.integerValue,
        mintAmount: databaseEntry.mintAmount.integerValue,
        id: projects[i].id
      }

      revisedArray.push(projectDetails)
    }
    const revisedArrayData = await Promise.all(revisedArray)
    setProjects(revisedArrayData)
  }

  useEffect(() => {
    setProjects()
    fetchProjects()
  }, [])

  return (
    <div className="admin_background">
      <div>
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Link className="submit_button" state={{ id: state?.id }} to={'/dashboard'}>
            Return to dashboard
          </Link>
        </div>

        {submit === true && (
          <div style={{ fontFamily: 'MSBEE', textAlign: 'center', fontSize: 20 }}>
            Confirm Entry
          </div>
        )}

        <div className="projects_overview">
          {submit === false && (
            <>
              <div className="project_input">
                <div>Contract</div>
                <input className="projects_input" style={{ fontFamily: 'MSBEE', fontWeight: 'bolder', color: '#DF4B4B' }} onChange={(x) => setContract(x.target.value)}></input>
              </div>

              <div className="project_input">
                <div>Discord</div>
                <input className="projects_input" style={{ fontFamily: 'MSBEE', fontWeight: 'bolder', color: '#DF4B4B' }} onChange={(x) => setDiscord(x.target.value)}></input>
              </div>

              <div className="project_input">
                <div>Name</div>
                <input className="projects_input" style={{ fontFamily: 'MSBEE', fontWeight: 'bolder', color: '#DF4B4B' }} onChange={(x) => setName(x.target.value)}></input>
              </div>

              <div className="project_input">
                <div>Review</div>
                <input className="projects_input" style={{ fontFamily: 'MSBEE', fontWeight: 'bolder', color: '#DF4B4B' }} onChange={(x) => setReview(x.target.value)}></input>
              </div>

              <div className="project_input">
                <div>Supply</div>
                <input className="projects_input" style={{ fontFamily: 'MSBEE', fontWeight: 'bolder', color: '#DF4B4B' }} type="number" onChange={(x) => setSupply(x.target.value)}></input>
              </div>

              <div className="project_input">
                <div>Website</div>
                <input className="projects_input" style={{ fontFamily: 'MSBEE', fontWeight: 'bolder', color: '#DF4B4B' }} onChange={(x) => setWebsite(x.target.value)}></input>
              </div>

              <div className="project_input">
                <div>WL Date</div>
                <input className="projects_input" style={{ fontFamily: 'MSBEE', fontWeight: 'bolder', color: '#DF4B4B' }} type="date" onChange={(x) => setProjectDate(x.target.value)}></input>
              </div>

              <div className="project_input">
                <div>WL Ethereum Price</div>
                <input className="projects_input" style={{ fontFamily: 'MSBEE', fontWeight: 'bolder', color: '#DF4B4B' }} onChange={(x) => setWlEth(x.target.value)}></input>
              </div>

              <div className="project_input">
                <div>WL USD Price</div>
                <input className="projects_input" style={{ fontFamily: 'MSBEE', fontWeight: 'bolder', color: '#DF4B4B' }} onChange={(x) => setWlUsd(x.target.value)}></input>
              </div>

              <div className="project_input">
                <div>Mint Amount</div>
                <input className="projects_input" style={{ fontFamily: 'MSBEE', fontWeight: 'bolder', color: '#DF4B4B' }} type="number" onChange={(x) => setMintAmount(x.target.value)}></input>
              </div>

              <div className="project_input">
                <div>Whitelist Amount</div>
                <input className="projects_input" style={{ fontFamily: 'MSBEE', fontWeight: 'bolder', color: '#DF4B4B' }} type="number" onChange={(x) => setWlAmount(x.target.value)}></input>
              </div>
            </>
          )}

          {submit === true && (
            <>
              <div style={{ color: '#DF4B4B' }} className="project_input">
                <div>Contract</div>
                {contract}
              </div>

              <div style={{ color: '#DF4B4B' }} className="project_input">
                <div>Discord</div>
                {discord}
              </div>

              <div style={{ color: '#DF4B4B' }} className="project_input">
                <div>Name</div>
                {name}
              </div>

              <div style={{ color: '#DF4B4B' }} className="project_input">
                <div>Review</div>
                {review}
              </div>

              <div style={{ color: '#DF4B4B' }} className="project_input">
                <div>Supply</div>
                {supply}
              </div>

              <div style={{ color: '#DF4B4B' }} className="project_input">
                <div>Website</div>
                {website}
              </div>

              <div style={{ color: '#DF4B4B' }} className="project_input">
                <div>WL Date</div>
                {wldate}
              </div>

              <div style={{ color: '#DF4B4B' }} className="project_input">
                <div>WL Ethereum Price</div>
                {wleth}
              </div>

              <div style={{ color: '#DF4B4B' }} className="project_input">
                <div>WL USD Price</div>
                {wlusd}
              </div>

              <div style={{ color: '#DF4B4B' }} className="project_input">
                <div>Mint Amount</div>
                {mintAmount}
              </div>

              <div style={{ color: '#DF4B4B' }} className="project_input">
                <div>Whitelist Amount</div>
                {wlAmount}
              </div>
            </>
          )}
        </div>
        
        {submit === false && (
          <div>
            <button className="submit_button" onClick={() => setSubmit(!submit)}>Submit Project</button>
          </div>
        )}
        
        {submit === true && (
          <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
            <button className="submit_button" onClick={() => addProjectItem()}>Submit Project</button>
            <button className="submit_button" onClick={() => setSubmit(!submit)}>Reset</button>
          </div>
        )}

        <div className="project_input">
          Current Projects
        </div>

        <section className="projects_overview">
          {projects && projects.map(items =>
            <div className="project_card">
              <div style={{ }}>
                ID: {items.id}
              </div>

              <div style={{ }}>
                Name: {items.name}
              </div>

              <div style={{ }}>
                Contract: {items.contract}
              </div>

              <div style={{ }}>
                WL: {items.wlAmount}
              </div>

              <div style={{ }}>
                Mint: {items.mintAmount}
              </div>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 'auto' }}>
                <button style={{ background: '#414141', padding: 5, marginLeft: 'auto', textTransform: 'uppercase' }} className="submit_button">
                  edit
                </button>

                <button onClick={() => deleteProjectById(items.id)} style={{ background: '#414141', padding: 5, marginLeft: 'auto', textTransform: 'uppercase' }} className="submit_button">
                  delete
                </button>
              </div>
            </div>
          )}
        </section>
      </div>
    </div>
  )
}
