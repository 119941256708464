import './styles/App.css';

import { Helmet } from 'react-helmet';
import Home from './components/Home/Home';
// import Navbar from './components/Navbar/Navbar';

function App() {
  return (
    <>
      <Helmet>
        <title>DSKDAO // HOME</title>
        <meta name="description" content="Bringing value to our community before anything else." />
      </Helmet>

      <div className="App">
        <Home />
      </div>
    </>
  );
}

export default App;
