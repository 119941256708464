import '../../styles/Chat.css';

import React, { useEffect, useState } from 'react';
import { useLocation, Link } from "react-router-dom";

import { user, getChat } from '../../server/Firebase';

export default function Chat() {
  const { state } = useLocation();
  const [userInformation, setUserInformation] = useState("");
  const [chatMap, setChatMap] = useState([]);

  async function chatDisplay() {
    const chat = await getChat()

    setChatMap(chat)
  }

  useEffect(() => {
    if(user) {
      setUserInformation(user)
    }
    chatDisplay()
  }, [])

  return (
    <div style={{ background: "#111111", height: "100vh", padding: 0, margin: 0, position: 'absolute', left: 0, right: 0, top: 0 }}>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          position: "absolute",
          justifyContent: "space-between",
          marginTop: 10,
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
          paddingLeft: 10,
          paddingRight: 10,
          width: "95%",
          minWidth: "95%",
          maxWidth: "95%"
        }}
      >

        <div>
          <Link to="/dashboard" state={{ id: state.id }}>
            <button className="menu_button">Dashboard</button>
          </Link>
        </div>

        <div>
          <button className="menu_button">{userInformation.username}</button>
        </div>
      </div>

      <div className="chat_section">
        <input className="chat_box" placeholder="Chat.."></input>
        <button className="send_button">{'>'}</button>
      </div>

      <img 
        src="https://i.imgur.com/tgnu7wZ.png"
        alt=""
        style={{
          position: 'absolute',
          bottom: 0,
          zIndex: 1
        }}
      ></img>

      <div style={{ position: 'absolute', top: 100, bottom: 100 }}>
        {chatMap && chatMap.map(chat =>
          <ViewChat chat={chat} />
        )}
      </div>
    </div>
  )

  function ViewChat(props) {
    const { message, photoURL, uid, username } = props.chat;
    console.log(message)
    return (
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div>
          <img style={{
            height: 35,
            width: 35,
            border: 'white',
            background: 'white', 
            borderRadius: 15,
            marginRight: 10,
            marginLeft: 10
          }} alt="PFP" src={photoURL}></img>
        </div>
        <div className="message_box">
          <div style={{ background: "#212121", padding: 5, marginLeft: -15, marginTop: -15 }}>{username}</div>
          <div style={{ marginTop: 10 }}>{message}</div>
          {/* <div>{createdAt}</div> */}
          {/* <div>{photoURL}</div> */}
          {/* <div>{uid}</div> */}
        </div>
      </div>
    )
  }
}
