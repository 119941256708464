import "../../styles/Dashboard.css";
import "../../styles/ProjectList/ProjectList.css";

import React, { useState, useEffect } from "react";
import numeral from "numeral";
import { useLocation } from "react-router-dom";

import { ConnectMetamask, WalletConnect } from "../../context/WalletLogin";
import { readProjects, wlProjects } from "../../server/Firebase";
import { getUser } from "../../server/Firebase";

import Sidebar from "./Sidebar";
import SearchBar from "./SearchBar";
import Chart from "./ChartDisplay";

import GridTitle from "../../assets/dashboard/grid_title.svg";
import loading from "../../assets/loading.svg";

export default function Dashboard() {
  const { state } = useLocation();

  const [projectsArray, setProjectsArray] = useState([]);
  const [notLoggedIn, setLoggedIn] = useState(true);

  const [connect, setConnect] = useState(true);

  const [walletAddress, setWalletAddress] = useState("");
  const [userImage, setUserImage] = useState("");
  const [email, setEmail] = useState("");
  const [discordCode, setDiscordCode] = useState("");
  const [discordLinked, setDiscordLinked] = useState(false);

  // Connectivity
  const [login, setLoggingIn] = useState(false);

  async function getCurrentUser(wallet) {
    const user = await getUser(wallet);

    setWalletAddress(wallet);
    setUserImage(user?.image);
    setEmail(user?.email);
    setLoggedIn(false);
    setConnect(true);
  }

  async function connectWallet() {
    setConnect(false);
  }

  async function loginMetamask() {
    setLoggingIn(true);

    const wallet = await ConnectMetamask();
    console.log(wallet.code)
    
    if (wallet.code === 4001) {
      setLoggingIn(false);
      setConnect(false);
      notLoggedIn(true);
      return;
    };

    if (wallet.code === -32603) {
      setLoggingIn(false);
      setConnect(false);
      notLoggedIn(true);
      return;
    };

    const user = await getUser(wallet);

    setTimeout(() => {
      setWalletAddress(wallet);
      setUserImage(user?.image);
      setDiscordCode(user.discordCode);
      setDiscordLinked(user.discordLinked);
      setLoggedIn(false);
      setConnect(!connect);
    }, 1000)
  }

  async function loginWalletconnect() {
    setLoggingIn(true);

    const wallet = await WalletConnect();
    console.log(wallet.code)
    
    if (wallet.code === 4001) {
      setLoggingIn(false);
      setConnect(false);
      notLoggedIn(true);
      return;
    };

    if (wallet.code === -32603) {
      setLoggingIn(false);
      setConnect(false);
      notLoggedIn(true);
      return;
    };

    const user = await getUser(wallet);

    setTimeout(() => {
      setWalletAddress(wallet);
      setUserImage(user?.image);
      setDiscordCode(user.discordCode);
      setDiscordLinked(user.discordLinked);
      setLoggedIn(false);
      setConnect(!connect);
    }, 1000)
  }

  async function fetchProjects() {
    const whitelist_projects = await wlProjects;

    if(whitelist_projects) {
      const projects = await wlProjects;

      let projectsFound = [];
  
      for (let i = 0; i < projects.length; i++) {
        const itemArray = projects[i].project;
  
        const itemDetails = {
          id: projects[i].id,
          name: itemArray.name.stringValue,
          review: itemArray.review.stringValue,
          supply: itemArray.supply.integerValue,
          website: itemArray.website.stringValue,
          wldate: itemArray.wldate.stringValue,
          wleth: itemArray.wleth.doubleValue,
          wlusd: itemArray.wlusd.doubleValue,
          contract: itemArray.contract.stringValue,
          discord: itemArray.discord.stringValue,
          day: itemArray.day.integerValue,
          month: itemArray.month.stringValue,
          wlAmount: itemArray.wlAmount.integerValue,
          mintAmount: itemArray.mintAmount.integerValue,
          dayFormat: itemArray.dayFormat.stringValue,
          timestamp: itemArray.timestamp.timestampValue,
          date: itemArray.wldate.stringValue,
        };
  
        projectsFound.push(itemDetails);
      }
  
      const projectsList = await Promise.all(projectsFound);
      setProjectsArray(projectsList);
    } else {
      const projects = await readProjects();

      let projectsFound = [];
  
      for (let i = 0; i < projects.length; i++) {
        const itemArray = projects[i].project;
  
        const itemDetails = {
          id: projects[i].id,
          name: itemArray.name.stringValue,
          review: itemArray.review.stringValue,
          supply: itemArray.supply.integerValue,
          website: itemArray.website.stringValue,
          wldate: itemArray.wldate.stringValue,
          wleth: itemArray.wleth.doubleValue,
          wlusd: itemArray.wlusd.doubleValue,
          contract: itemArray.contract.stringValue,
          discord: itemArray.discord.stringValue,
          day: itemArray.day.integerValue,
          month: itemArray.month.stringValue,
          wlAmount: itemArray.wlAmount.integerValue,
          mintAmount: itemArray.mintAmount.integerValue,
          dayFormat: itemArray.dayFormat.stringValue,
          timestamp: itemArray.timestamp.timestampValue,
          date: itemArray.wldate.stringValue,
        };
  
        projectsFound.push(itemDetails);
      }
  
      const projectsList = await Promise.all(projectsFound);
      setProjectsArray(projectsList);
    }
  }

  async function searchWhitelist(term) {
    if (term) {
      const keyword = term.toLowerCase();

      const searchedTerms = [];
      for (let i = 0; i < projectsArray.length; i++) {
        const { name, date, day, month, review, website } = projectsArray[i];

        if (
          name.toLowerCase().includes(keyword) ||
          day.toLowerCase().includes(term) ||
          month.toLowerCase().includes(term) ||
          review.toLowerCase().includes(term) ||
          date.toLowerCase().includes(term) ||
          website.toLowerCase().includes(term)
        ) {
          searchedTerms.push(projectsArray[i]);
        }
      }
      const searchedQuery = await Promise.all(searchedTerms);
      setProjectsArray(searchedQuery);
    } else {
      fetchProjects();
    }
  }

  useEffect(() => {
    fetchProjects();
    if (state?.id) {
      getCurrentUser(state?.id);
    }
  }, []);

  return (
    <div className="dashboard_background">
      {notLoggedIn === false && (
        <>
          <Sidebar
            discordLinked={discordLinked}
            discordCode={discordCode}
            userImage={userImage}
            user={walletAddress}
            email={email}
          />

          <div>
            <SearchBar
              discordLinked={discordLinked}
              discordCode={discordCode}
              userImage={userImage}
              user={walletAddress}
              email={email}
              name={(x) => searchWhitelist(x)}
              search={true}
            />
          </div>

          <Chart dates={projectsArray} />

          <div className="projects_table_div">
            <img alt="Grid_Title" className="table_title" src={GridTitle}></img>

            <section className="projects_table">
              {projectsArray &&
                projectsArray.map((data) => <ViewProject data={data} />)}
            </section>
          </div>

          <div
            style={{
              fontFamily: "MSBEE",
              fontWeight: 1000,
              textAlign: "center",
              color: "#df4b4b",
              fontSize: 20,
              padding: 10,
            }}
          >
            Mint Alert is currntly in BETA.
            <p>Features might be buggy.</p>
          </div>
        </>
      )}

      {notLoggedIn === true && (
        <div className="App">
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <div
              style={{
                color: "white",
                justifyContent: "center",
                display: "flex",
                alignContent: "center",
                marginTop: "auto",
                marginBottom: "auto",
                height: "100%",
                fontSize: 25,
              }}
            >
              <span
                style={{
                  color: "#DF4B4B",
                  fontStyle: "bolder",
                  marginRight: 5,
                }}
              >
                //{" "}
              </span>{" "}
              DSKDAO TOOLS
            </div>

            <div
              style={{
                color: "white",
                justifyContent: "center",
                display: "flex",
                alignContent: "center",
                marginTop: "auto",
                marginBottom: "auto",
                height: "100%",
                fontSize: 25,
              }}
            >
              <span
                style={{
                  color: "#DF4B4B",
                  fontStyle: "bolder",
                  marginRight: 5,
                }}
              >
                Alpha
              </span>{" "}
              v1.
            </div>

            <button
              onClick={() => connectWallet()}
              style={{
                background: "#DF4B4B",
                border: "transparent",
                padding: 10,
                borderRadius: 10,
                fontFamily: "MSBEE",
                textTransform: "uppercase",
                color: "white",
                cursor: "pointer",
                marginTop: 10,
              }}
            >
              Connect
            </button>
          </div>
        </div>
      )}

      {connect === false && (
        <div>
          <div /*  onClick={() => closeModal()} */ className="modal_window">
            <div className="modal">
              <div style={{ width: "100%" }}>
                {login === false && (
                  <>
                    <div className="modal_title">Choose Wallet</div>{" "}
                    <button
                      onClick={() => loginMetamask()}
                      className="modal_button"
                    >
                      metamask
                    </button>
                    <button
                      onClick={() => loginWalletconnect()}
                      className="modal_button"
                    >
                      walletconnect
                    </button>
                  </>
                )}

                {login === true && (
                  <div
                    style={{
                      textAlign: "center",
                      fontFamily: "MSBEE",
                      fontWeight: "bolder",
                      color: "white",
                      textTransform: "uppercase",
                    }}
                  >
                    Connecting wallet...
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  function ViewProject(props) {
    const {
      name,
      supply,
      wldate,
      contract,
      discord,
      website,
      review,
      wleth,
      wlusd,
    } = props.data;

    return (
      <div className="project_entry">
        <div style={{ width: 25 }}>
          <input
            id="d1"
            onChange={(x) => console.log(x)}
            type="checkbox"
            className="project_select"
          ></input>
          <label for="d1"></label>
        </div>

        <div title={name} className="project_title">
          {name}
        </div>

        <div
          style={{
            width: 65,
            minWidth: 65,
            display: "grid",
            justifyContent: "left",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {numeral(supply).format("Oa")}
        </div>

        <div
          style={{
            width: 100,
            minWidth: 100,
            display: "grid",
            justifyContent: "left",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {wldate.slice(0, 10)}
        </div>

        <div
          style={{
            width: 120,
            minWidth: 120,
            display: "grid",
            justifyContent: "left",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {contract}
        </div>

        <div
          style={{
            width: 120,
            minWidth: 120,
            display: "grid",
            justifyContent: "center",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <button
            disabled
            style={{
              display: "grid",
              background: "#d85353",
              border: "transparent",
              padding: "5px",
              marginLeft: "auto",
              marginRight: "auto",
              width: 65,
              borderRadius: 10,
              cursor: "pointer",
              color: "white",
              fontFamily: "MSBEE",
              textTransform: "uppercase",
            }}
            onClick={() => window.open(discord)}
          >
            Join
          </button>
        </div>

        <div
          style={{
            width: 215,
            minWidth: 215,
            display: "grid",
            justifyContent: "center",
            overflow: "hidden",
            position: "relative",
          }}
          onClick={() => window.open("https://" + website)}
        >
          <button
            disabled
            style={{
              display: "grid",
              background: "#d85353",
              border: "transparent",
              padding: "5px",
              marginLeft: "auto",
              marginRight: "auto",
              width: 65,
              borderRadius: 10,
              cursor: "pointer",
              color: "white",
              fontFamily: "MSBEE",
              textTransform: "uppercase",
            }}
            onClick={() => window.open(website)}
          >
            Visit
          </button>
        </div>

        <div
          style={{
            width: 65,
            minWidth: 65,
            display: "grid",
            justifyContent: "center",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {review}
        </div>

        <div
          style={{
            width: 80,
            minWidth: 80,
            display: "grid",
            justifyContent: "center",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {wleth}
        </div>

        <div>${wlusd}</div>
      </div>
    );
  }
}
