import "../../styles/Search/SearchBar.css";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import search_bar_logo from "../../assets/dashboard/search_bar_logo.svg";
import noti_bar from "../../assets/dashboard/noti_bell.svg";
import menu_dashboard from "../../assets/menu icons/menu.svg";
import create from "../../assets/menu icons/create.svg";
import dashboard_settings from "../../assets/menu icons/settings.svg";
import store from "../../assets/menu icons/store.svg";
// import chat from '../../assets/menu icons/chat.svg';

export default function SearchBar(props) {
  const [text, setText] = useState("");
  const [userMenu, setUsermenu] = useState(false);
  const [userNotis, setUsernotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  function searchNames(name) {
    setText(name);
    props.name(name);
  }

  async function openUsername() {
    setUsermenu(!userMenu);
    setUsernotifications(false);
  }

  async function openNotifications() {
    setUsernotifications(!userNotis);
    setUsermenu(false);
  }

  useEffect(() => {
    console.log(props?.user);
  }, []);

  return (
    <div className="top_div">
      {props.search === true && (
        <>
          {text.length === 0 && (
            <img
              className="search_bar_logo"
              alt="Search bar"
              src={search_bar_logo}
            ></img>
          )}
          <input
            onChange={(x) => searchNames(x.target.value)}
            className="search_bar"
          ></input>
        </>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
        >
          <button
            onClick={() => openNotifications()}
            style={{ marginRight: 20 }}
            title="Notifications"
            className="notifications_button"
          >
            <img alt="Notifications" src={noti_bar}></img>
          </button>

          <button
            onClick={() => openUsername()}
            title="User Options"
            className="notifications_button"
          >
            <img
              alt="Profile_Image"
              style={{ width: 45, height: 45, borderRadius: 3 }}
              src={
                props?.userImage
                  ? props.userImage
                  : "https://i.imgur.com/gUjJBid.jpg"
              }
            ></img>
          </button>
        </div>
      </div>

      {userMenu === true && (
        <>
          <button
            onClick={() => setUsermenu(!userMenu)}
            style={{
              display: "grid",
              position: "absolute",
              right: 10,
              zIndex: 101,
              background: "#212121a1",
              borderRadius: "10px",
              color: "white",
              fontFamily: "MSBEE",
              textTransform: "uppercase",
              marginTop: 10,
              marginBottom: 10,
              padding: 10,
              cursor: "pointer",
              border: "transparent",
            }}
          >
            Close
          </button>

          <div className="user_menu">
            <div
              style={{
                display: "grid",
                justifyContent: "center",
              }}
            >
              <div className="button_map">
                <Link
                  style={{ textDecoration: "none" }}
                  to="/dashboard"
                  state={{ id: props.user }}
                >
                  <button
                    onClick={() => setUsermenu(!userMenu)}
                    className="fullscreen_menu_button"
                  >
                    <img
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}
                      height={25}
                      alt="dashboard"
                      src={menu_dashboard}
                    ></img>
                    Dashboard
                  </button>
                </Link>

                <Link
                  style={{ textDecoration: "none" }}
                  to="/settings"
                  state={{ id: props.user }}
                >
                  <button
                    onClick={() => setUsermenu(!userMenu)}
                    className="fullscreen_menu_button"
                  >
                    <img
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}
                      height={25}
                      alt="dashboard"
                      src={dashboard_settings}
                    ></img>

                    Settings
                  </button>
                </Link>

                <Link
                  style={{ textDecoration: "none" }}
                  to="/gallery"
                  state={{ id: props.user }}
                >
                  <button
                    onClick={() => setUsermenu(!userMenu)}
                    className="fullscreen_menu_button"
                  >
                    <img
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}
                      height={25}
                      alt="dashboard"
                      src={create}
                    ></img>
                    DSK-DAOLL-E
                  </button>
                </Link>

                {/* <Link style={{ textDecoration: 'none' }} to="/chat" state={{ id: props.user }}>
                  <button className="menu_button" title="Create">Chat</button> 
                </Link> */}

                <Link
                  style={{ textDecoration: "none" }}
                  to="/store"
                  state={{ id: props.user }}
                >
                  <button className="fullscreen_menu_button">
                    <img
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}
                      height={25}
                      alt="dashboard"
                      src={store}
                    ></img>
                    Marketplace
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </>
      )}

      {userNotis === true && (
        <>
          <button
            onClick={() => setUsernotifications(!userNotis)}
            style={{
              display: "grid",
              position: "absolute",
              right: 10,
              zIndex: 101,
              background: "#212121a1",
              borderRadius: "10px",
              color: "white",
              fontFamily: "MSBEE",
              textTransform: "uppercase",
              marginTop: 10,
              marginBottom: 10,
              padding: 10,
              cursor: "pointer",
              border: "transparent",
            }}
          >
            Close
          </button>

          <div className="user_menu">
            <div
              style={{
                fontSize: 18,
                display: "grid",
                justifyContent: "center",
                paddingBottom: 10,
                paddingTop: 10,
                textTransform: "uppercase",
                marginBottom: 15,
                position: "absolute",
                top: 50,
                fontFamily: "MSBEE",
                color: "white",
                padding: 10,
              }}
            >
              Notifications ({notifications.length})
            </div>
          </div>
        </>
      )}
    </div>
  );
}
