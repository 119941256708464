import { ethers, providers, utils } from 'ethers';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/ethereum-provider";

// import tokenABI from '../context/LootboxABI.json';
// const LOOTBOX_ADDRESS = "0x2e2e475568d62F92D23e7c4f942ef5Cf90Bc1666"; // Lootbox address

export let provider_data;
export let user_address;

async function signAddress(wallet) {
  try {
    const from = wallet;
    const date = new Date();
    const showDate = date.toUTCString();
    const signMessage = `DSKDAO | Validating Human Verification \nDate: ${showDate}`;
    const sign = await window.ethereum.request({
      method: 'personal_sign',
      params: [signMessage, from],
    }).catch((err) => {
      if (err) {
        console.log("ERROR ERROR ERROR")
        return false;
      }
    })
    // console.log("Here is the sign:")
    // console.log(sign.code)
    if (sign.code === 4001) return;
    return sign;
  } catch (err) {
    return false;
  }
}

export async function ConnectMetamask() {
  try {
    // await ChainId();

    if (window.ethereum) {
      console.log("Hello from the other side!")

      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

      const sign = await signAddress(accounts[0]);

      if (sign === false) return;
      if (accounts[0].code === 4001) return;
      if (accounts[0].code === -32603) return;

      return accounts[0];
    } else return alert("Please open this site in your wallet browser!")
  } catch (err) {
    console.error(err);
    return err;
  }
}

function accountsChanged(accounts) {
  console.log("accountsChanged", accounts);
  return accounts[0]
}

export async function WalletConnect() {
  try {
    await ChainId();

    if (window.ethereum) {
      const web3Modal = new Web3Modal({
        network: "mainnet",
        cacheProvider: true,
        providerOptions: {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              infuraId: process.env.REACT_APP_INFURA_ID,
            },
          },
        },
      });

      const web3Provider = await web3Modal.connect();
      web3Provider.on("accountsChanged", accountsChanged);
      const accounts = (await web3Provider.enable());
      const provider = new providers.Web3Provider(web3Provider);
      provider_data = provider;

      console.log(`Wallet connecting to ${accounts[0]}`)
      return accounts[0];
    } else return alert("Please open this site in your wallet browser!");
  } catch (err) {
    console.log(err)
  }
}

export async function ChainId() {
  const networkId = await window.ethereum.chainId;
  console.log("Current Network")
  console.log(networkId)
  if (networkId !== "0x1") {
    console.log("Network ID did not pass")
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: "0x1" }], // chainId must be in hexadecimal numbers
    });
  } else {
    console.log("Network ID did pass!")
  }
}

export async function WalletBalance() {
  const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
  const web3 = new Web3(window.ethereum);

  const balance = await web3.eth.getBalance(account[0]).then((balance) => {
    const userBalance = web3.utils.fromWei(balance)
    return userBalance;
  });

  return balance;
}