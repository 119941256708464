import '../../styles/Raffles/Login.css';

import React from 'react';

import Metamask_logo from '../../assets/raffles/metamask_logo.svg';

export default function ConnectButton(props) {
  return (
    <div className="login_div">
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 10,
          }}
        >
          <span
            style={{
              color: "#DF4B4B",
              fontStyle: "bolder",
              marginRight: 5,
            }}
          >
            {"//"}
          </span>{" "}
          RAFFLES
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 10,
            marginBottom: 15,
            fontSize: 15,
          }}
        >
          <span
            style={{
              color: "#DF4B4B",
              fontStyle: "bolder",
              marginRight: 5,
            }}
          >
            Beta
          </span>{" "}
          v1.
        </div>
      </div>

      {props.connect === false && (
        <>
          <button onClick={() => props.signIn()} className="login_button">
            MetaMask <img alt="" height={22} src={Metamask_logo}></img>
          </button>
        </>
      )}
    </div>
  );
}
