import "../../styles/Buttons.css";
import "../../styles/Raffles/Cards.css";
import "../../styles/Raffles/Raffles.css";

import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

import {
  getUser,
  burnTickets,
  user,
  raffleWinners,
  vouchersData,
  saveWinner,
} from "../../server/Firebase";
import { ConnectMetamask } from "../../context/WalletLogin";
import { getVouchers } from "../../server/Firebase";

import ticketsIcon from "../../assets/raffles/ticket-icon.svg";
import checkMark from "../../assets/raffles/check_mark.svg";
import DisplayCards from "./DisplayCards";
import BurnModal from "./BurnModal";
import ConnectButton from "./ConnectButton";
import archive_logo from "../../assets/raffles/archive.svg";
import character from "../../assets/home page/character.png";
// const fetch = (...args) => import('node-fetch').then(({default: fetch}) => fetch(...args));

export default function Raffles() {
  const burnDiv = useRef();

  // Wallet connect
  const [connect, setConnect] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");

  // Set User Information
  const [userInformation, setUserInformation] = useState([]);
  const [userTickets, setUserTickets] = useState([])

  // Linked Status
  const [discordLinked, setDiscordLinked] = useState(false);
  const [discordCode, setDiscordCode] = useState(true);

  // Tickets Balance
  const [balance, setBalance] = useState(0);

  // Burning Process
  const [burn, setBurnWindow] = useState(false);
  const [burningProcess, setBurningProcess] = useState(false);

  // Vouchers
  const [vouchers, setVouchers] = useState([]);
  const [viewVouchers, setViewVouchers] = useState(false);
  const [image, setImage] = useState("");

  // Burn modal
  const [burnModalItemPrice, setBurnModalItemPrice] = useState("");
  const [maxPerWalletAddress, setMaxPerWalletAddress] = useState("");
  const [item, setItem] = useState("");

  // Community Stats
  const [totalBurned, setTotalBurned] = useState(0);
  const [totalVouchers, setTotalVouchers] = useState(0);

  async function connectWallet() {
    setConnect(!connect);
  }

  async function metamaskSignIn() {
    const userWallet = await ConnectMetamask();

    if (!userWallet.startsWith("0x")) return alert("Use Metamask in browser.");

    setWalletAddress(userWallet);
    getUserInformation(userWallet);
    setConnect(false);

    const vouchersData = await getVouchers();

    setTotalBurned(vouchersData[0].ticketsBurned);
    setTotalVouchers(vouchersData[0].vouchersTotal);
  }

  async function getUserInformation(wallet) {
    const userData = await getUser(wallet);

    setDiscordLinked(userData.discordLinked);
    setDiscordCode(userData.discordCode);
    setBalance(userData.balance);
    setUserInformation(userData);
    setUserTickets(userData.tickets);

    const tickets = userData.tickets.sort(({ date: a }, { date: b }) =>
      a > b ? -1 : a < b ? 1 : 0
    );

    setVouchers(tickets);
  }
  return (
    <div className="App">
      {!walletAddress && <WalletControl />}

      {discordLinked === false && walletAddress && (
        <DisplayDiscordCode data={discordCode} />
      )}

      {discordLinked === true && (
        <>
          <NavbarTop />

          <HeaderTop />

          <DisplayCards
            setItemToBurn={(x) => setItem(x)}
            setBurn={(x) => setBurnWindow(x)}
            setItemImage={(x) => setImage(x)}
            setItemPrice={(x) => setBurnModalItemPrice(x)}
            setMaxPerWallet={(x) => setMaxPerWalletAddress(x)}
            userTickets={userTickets}
          />
        </>
      )}

      {burn === true && !burningProcess && (
        <>
          <BurnModal
            Image={image}
            setBurn={(x) => setBurnWindow(x)}
            Item={item}
            Balance={balance}
            User={userInformation}
            MaxAmount={maxPerWalletAddress}
            ItemPrice={burnModalItemPrice}
            SetUserBalance={(x) => setBalance(x)}
            SetUserVouchers={(x) => setVouchers(x)}
            setModalBurn={(x) => setBurnWindow(x)}
          />
        </>
      )}

      {burningProcess === true && <BurnInProgress />}
    </div>
  );

  function DisplayDiscordCode(props) {
    // const { code } = props.data;
    return (
      <div
        style={{
          display: "grid",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          textAlign: "center",
          zIndex: 21,
        }}
      >
        <div
          style={{
            fontFamily: "MSBEE",
            fontSize: 24,
            color: "#DF4B4B",
          }}
        >
          {props.data}
        </div>

        <div
          style={{
            color: "white",
            maxWidth: 500,
            padding: 20,
          }}
        >
          Copy this code and use the /verify command in the DSKDAO Discord.
        </div>

        <div
          style={{
            color: "#DF4B4B",
            maxWidth: 500,
            padding: 20,
            background: "#212121a1",
            borderRadius: 10,
          }}
        >
          If you verified your account, refresh the page and sign in again to
          view the raffle.
        </div>
      </div>
    );
  }

  function BurnInProgress() {
    return (
      <div
        style={{
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "auto",
          marginRight: "auto",
          borderRadius: 10,
          background: "#212121a1",
          height: 250,
          width: 250,
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          marginTop: "auto",
          marginBottom: "auto",
          textAlign: "center",
          fontSize: 22,
          color: "white",
          textTransform: "uppercase",
        }}
      >
        Burn in progress...
      </div>
    );
  }

  function ViewVouchers() {
    return (
      <div>
        <button
          style={{
            background: "#313131",
            border: "transparent",
            color: "white",
            fontSize: 22,
            borderRadius: 10,
            cursor: "pointer",
            display: "grid",
            padding: 5,
          }}
          onClick={() => setViewVouchers(!viewVouchers)}
        >
          <img alt="" height={18} src={archive_logo}></img>
        </button>
      </div>
    );
  }

  function ViewVoucherDetails(props) {
    const { item, date, size } = props.data;
    return (
      <div
        style={{
          color: "white",
          textAlign: "left",
          background: "#313131a1",
          padding: 10,
          maxHeight: 250,
          borderRadius: 10,
          maxWidth: "100%",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 5,
          borderLeftColor: "white",
          borderLeftWidth: "2px",
          borderLeftStyle: "solid",
          borderRightColor: "white",
          borderRightWidth: "2px",
          borderRightStyle: "solid",
          zIndex: 31,
        }}
      >
        <div
          style={{
            fontSize: 25,
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          {item}
        </div>
        <hr
          style={{
            width: "100%",
          }}
        ></hr>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingRight: 5,
            padding: 3,
          }}
        >
          <img
            style={{ marginRight: 10 }}
            height={25}
            alt=""
            src={checkMark}
          ></img>{" "}
          {moment(new Date(date).getTime()).format("dddd, MMMM Do YYYY")}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: 3,
          }}
        >
          <img
            style={{ marginRight: 10 }}
            src={ticketsIcon}
            height={25}
            alt=""
          ></img>{" "}
          {size}
        </div>
      </div>
    );
  }

  function ViewVouchersOpen(props) {
    return (
      <>
        <button
          // className="general_buttons"
          style={{
            marginBottom: 25,
            position: "fixed",
            top: 15,
            right: 15,
            fontSize: 15,
            zIndex: 33,
            cursor: "pointer",
          }}
          onClick={() => setViewVouchers(!viewVouchers)}
        >
          X
        </button>

        <div
          style={{
            position: "fixed",
            width: "100%",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: "#df4b4b",
            fontFamily: "MSBee",
            height: "100dvh",
            overflow: "auto",
          }}
        >
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                padding: 20,
                color: "white",
                textAlign: "center",
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 18,
                textTransform: "uppercase",
              }}
            >
              You have {props.data?.length ? props.data.length : 0} voucher(s)!
            </div>
          </div>

          <div
            style={{
              overflow: "auto",
              padding: 5,
              margin: 5,
              borderRadius: 10,
              maxWidth: "90%",
              width: "100%",
              height: "90%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {props.data &&
              props.data.map((vouchersData_) => (
                <ViewVoucherDetails data={vouchersData_} />
              ))}
          </div>
        </div>
      </>
    );
  }

  function WalletControl() {
    return (
      <>
        <div className="connect">
          <ConnectButton
            connect={connect}
            walletAddress={walletAddress}
            connectWallet={() => connectWallet()}
            signIn={() => metamaskSignIn()}
          />
        </div>
      </>
    );
  }

  function NavbarTop() {
    return (
      <div className="navbar_top">
        {viewVouchers === false && <ViewVouchers />}

        {viewVouchers === true && <ViewVouchersOpen data={vouchers} />}

        {viewVouchers === false && (
          <div
            style={{
              width: "#313131",
            }}
          >
            <img
              style={{ marginRight: 5 }}
              alt=""
              height={18}
              src={ticketsIcon}
            ></img>
            {balance}
          </div>
        )}
      </div>
    );
  }

  function HeaderTop() {
    return (
      <>
        <div
          style={{
            display: "grid",
            fontFamily: "MSBEE",
            textTransform: "uppercase",
            textAlign: "center",
            marginTop: '5rem',
            marginBottom: '3rem',
            color: "white",
          }}
        >
          <div
            style={{
              fontSize: 24,
              color: "white",
            }}
          >
            Official DSKDAO Raffles
            <div
              style={{
                // color: "white",
                textAlign: "center",
                padding: 10,
                fontSize: 18,
                color: "#df4b4b",
              }}
            >
              <span>BETA</span> V1
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '3rem',
              maxHeight: 250
            }}
          >
            <div
              style={{
                display: "grid",
                alignItems: "center",
                // background: "#df4b4b",
                width: 150,
                padding: 10,
              }}
            >
              <span
                style={{
                  fontSize: "3rem",
                  marginLeft: 5,
                }}
              >
                {totalBurned}
              </span>
              Tickets Burned
            </div>

            <div
              style={{
                display: "grid",
                alignItems: "center",
                // background: "#df4b4b",
                width: 150,
                padding: 10,
              }}
            >
              <span
                style={{
                  fontSize: "3rem",
                  marginLeft: 5,
                }}
              >
                {totalVouchers}
              </span>
              Vouchers Bought
            </div>
          </div>
        </div>
      </>
    );
  }
}
