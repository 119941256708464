import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThirdwebProvider, ChainId } from "@thirdweb-dev/react";
// import { Helmet } from 'react-helmet';

import App from './App';
import Dashboard from './components/Alert/Dashboard';
import AddProject from './components/Alert/AddProject';
import Profile from './components/Alert/Profile';
import Gallery from './components/Gallery/Gallery';
import Chat from './components/Alert/Chat';
import Marketplace from './components/Alert/Marketplace';
import Raffles from './components/Raffle/Raffles';
import AddRaffleItems from './components/Raffle/AddRaffleItems';

// Offline 
// import Lootboxes from './components/Mint/Mintpage';
// import Burn from './components/Burn/Burn';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThirdwebProvider activeChain={ChainId.Goerli}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          {/* <Route path="/lootboxes" element={<Lootboxes />} />
          <Route path="/burn" element={<Burn />} /> */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/settings*" element={<Profile />} />
          <Route path="/admin" element={<AddProject/>} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/store" element={<Marketplace />} />
          <Route path="/add-item" element={<AddRaffleItems />} />
          <Route path="/raffles" element={<Raffles/>} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </ThirdwebProvider>
);
