import '../../styles/Chart/Chart.css';

import React, { useEffect, useState } from 'react';
import { Legend, ResponsiveContainer, AreaChart, XAxis, YAxis, Tooltip, Area } from 'recharts';


export default function ChartDisplay(dates) {
  const [chartDates, setChartDates] = useState([]);
  const [month, setMonth] = useState("");

  async function grabDates() {
    let chartDates = [];
    for(let i=0; i<dates.dates.length; i++) {
      const chartDetails = {
        day: Number(dates.dates[i].day),
        month: dates.dates[i].month,
        WL: Number(dates.dates[i].wlAmount),
        Mints: Number(dates.dates[i].mintAmount),
        date: dates.dates[i].wldate, 
        dayFormat: dates.dates[i].dayFormat
      }
      chartDates.push(chartDetails)
    }
    const sortedDates = chartDates.sort((a, b) => a.day - b.day)
    console.log(sortedDates)
    const datesCharted = await Promise.all(sortedDates)
    setChartDates(datesCharted)
    sortMonths("March")
  }

  async function sortMonths(month) {
    let chartDates = [];
    for(let i=0; i<dates.dates.length; i++) {
      if(dates.dates[i].month === month) {
        setMonth(dates.dates[i].month)
        const chartDetails = {
          day: Number(dates.dates[i].day),
          month: dates.dates[i].month,
          WL: Number(dates.dates[i].wlAmount) ? Number(dates.dates[i].wlAmount) : 0,
          Mints: Number(dates.dates[i].mintAmount) ? Number(dates.dates[i].mintAmount) : 0,
          date: dates.dates[i].wldate, 
          dayFormat: dates.dates[i].dayFormat
        }
        chartDates.push(chartDetails)
        const sortedArray = await Promise.all(chartDates)
        const sortedDates = sortedArray.sort((a, b) => a.day - b.day)
        setChartDates(sortedDates)
        console.log(sortedArray)
      }
    }
  }

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  async function monthForward() {
    if(months[months.indexOf(month)] === "December") {
      await setMonth(months[0]);

      const currentMonth = months[months.indexOf(month) + 1];
      console.log("Searching for month: ") 
      console.log(currentMonth)
  
      let chartDates = [];
  
      for (let i = 0; i < dates.dates.length; i++) {
        if(dates.dates[i].month === currentMonth.toString()) {
          const chartDetails = {
            day: Number(dates.dates[i].day),
            month: dates.dates[i].month,
            WL: Number(dates.dates[i].wlAmount) ? Number(dates.dates[i].wlAmount) : 0,
            Mints: Number(dates.dates[i].mintAmount) ? Number(dates.dates[i].mintAmount) : 0,
            date: dates.dates[i].wldate, 
            dayFormat: dates.dates[i].dayFormat
          }
          chartDates.push(chartDetails)
          const sortedArray = await Promise.all(chartDates)
          const sortedDates = sortedArray.sort((a, b) => a.day - b.day)
          setChartDates(sortedDates)
          console.log(sortedArray)
        } else return setChartDates()
      }
    } else {
      await setMonth(months[months.indexOf(month) + 1]);

      const currentMonth = months[months.indexOf(month) + 1];
      console.log("Searching for month: ") 
      console.log(currentMonth)
  
      let chartDates = [];
  
      for (let i = 0; i < dates.dates.length; i++) {
        if(dates.dates[i].month === currentMonth.toString()) {
          const chartDetails = {
            day: Number(dates.dates[i].day),
            month: dates.dates[i].month,
            WL: Number(dates.dates[i].wlAmount) ? Number(dates.dates[i].wlAmount) : 0,
            Mints: Number(dates.dates[i].mintAmount) ? Number(dates.dates[i].mintAmount) : 0,
            date: dates.dates[i].wldate, 
            dayFormat: dates.dates[i].dayFormat
          }
          chartDates.push(chartDetails)
          const sortedArray = await Promise.all(chartDates)
          const sortedDates = sortedArray.sort((a, b) => a.day - b.day)
          setChartDates(sortedDates)
          console.log(sortedArray)
        } else return setChartDates()
      }
    }
  }

  async function monthBackward() {
    if(months[months.indexOf(month)] === "January") {
      await setMonth(months[11]);

      const currentMonth = months[months.indexOf(month) - 1];
      console.log("Searching for month: ") 
      console.log(currentMonth)
  
      let chartDates = [];
  
      for (let i = 0; i < dates.dates.length; i++) {
        if(dates.dates[i].month === currentMonth.toString()) {
          const chartDetails = {
            day: Number(dates.dates[i].day),
            month: dates.dates[i].month,
            WL: Number(dates.dates[i].wlAmount) ? Number(dates.dates[i].wlAmount) : 0,
            Mints: Number(dates.dates[i].mintAmount) ? Number(dates.dates[i].mintAmount) : 0,
            date: dates.dates[i].wldate, 
            dayFormat: dates.dates[i].dayFormat
          }
          chartDates.push(chartDetails)
          const sortedArray = await Promise.all(chartDates)
          const sortedDates = sortedArray.sort((a, b) => a.day - b.day)
          setChartDates(sortedDates)
          console.log(sortedArray)
        } else return setChartDates()
      }
    } else {
      await setMonth(months[months.indexOf(month) - 1]);

      const currentMonth = months[months.indexOf(month) - 1];
      console.log("Searching for month: ") 
      console.log(currentMonth)
  
      let chartDates = [];
  
      for (let i = 0; i < dates.dates.length; i++) {
        if(dates.dates[i].month === currentMonth.toString()) {
          const chartDetails = {
            day: Number(dates.dates[i].day),
            month: dates.dates[i].month,
            WL: Number(dates.dates[i].wlAmount) ? Number(dates.dates[i].wlAmount) : 0,
            Mints: Number(dates.dates[i].mintAmount) ? Number(dates.dates[i].mintAmount) : 0,
            date: dates.dates[i].wldate, 
            dayFormat: dates.dates[i].dayFormat
          }
          chartDates.push(chartDetails)
          const sortedArray = await Promise.all(chartDates)
          const sortedDates = sortedArray.sort((a, b) => a.day - b.day)
          setChartDates(sortedDates)
          console.log(sortedArray)
        } else return setChartDates()
      }
    }
  }


  useEffect(() => {
    grabDates()
  }, [dates])


  return (
    <>
      <div className="chart">
        <div className="chart_whitelist_selection">
          <div style={{ paddingRight: 20 }}>Whitelist</div>
          <button style={{ cursor: 'pointer' }} onClick={() => monthBackward()} className="month_button">
            {"<"}
          </button>
          <div style={{ paddingLeft: 5, paddingRight: 5, cursor: 'pointer' }}>{month}</div>
          <button onClick={() => monthForward()} className="month_button">
            {">"}
          </button>
        </div>

        {!chartDates && (
          <div style={{ textAlign: 'center', fontFamily: "MSBEE", color: 'white', display: 'grid', justifyContent: 'center', alignItems: 'center', paddingTop: 150 }}>
            No whitelist's found for {month}.
          </div>
        )}

        {chartDates && chartDates.length > 0 && (
          <>
            <ResponsiveContainer>
              <AreaChart
                data={chartDates}
                margin={{ top: 50, right: 25, left: 20, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#ffffff" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#ffffff" stopOpacity={0} />
                  </linearGradient>

                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#000000" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#000000" stopOpacity={0} />
                  </linearGradient>
                </defs>

                {/* <XAxis textAnchor={"end"} tickLine={false} axisLine={false} tickMargin={10} dataKey={(x) => `${x.date}`} tick={{ color: '#ffffff', fontFamily: 'MSBEE' }} type="category" /> */}
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  tickMargin={20}
                  padding={{ top: 5, bottom: 5 }}
                  tick={{ color: "#ffffff", fontFamily: "MSBEE" }}
                  type="number"
                />

                <Tooltip
                  content={<BoxView />}
                  contentStyle={{
                    background: "black",
                    border: "transparent",
                    fontFamily: "MSBEE",
                    color: "white",
                    borderRadius: 5,
                  }}
                  labelStyle={{ color: "white" }}
                  itemStyle={{ color: "white" }}
                />

                <Legend
                  verticalAlign="bottom"
                  height={40}
                  iconType={"circle"}
                  wrapperStyle={{ fontFamily: "MSBEE" }}
                />

                <Area
                  type="monotone"
                  dataKey="WL"
                  stroke="#000000"
                  label={{
                    fill: "#ffffff",
                    fontSize: 20,
                    fontWeight: "bolder",
                    fontFamily: "MSBEE",
                  }}
                  fillOpacity={1}
                  fill="url(#colorPv)"
                />
                <Area
                  type="monotone"
                  dataKey="Mints"
                  stroke="#ffffff"
                  label={{
                    fill: "#ffffff",
                    fontSize: 20,
                    fontWeight: "bolder",
                    fontFamily: "MSBEE",
                  }}
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </>
        )}
      </div>
    </>
  );
}

const BoxView = ({ active, payload, label}) => {

  if(active) {
    return (
      <div style={{ color: 'white', fontSize: 15, fontFamily: 'MSBEE', textAlign: 'left', background: '#000000', padding: 10, borderRadius: 10 }}>
         {payload[0].payload.date}
        <p></p>
        {payload[0].payload.Mints} Mints
        <p></p>
        {payload[0].payload.WL} WL
      </div>
    )
  }

  return null;
}
