import "../../styles/Gallery.css";

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { viewGallery, user, getItems, itemShopData } from "../../server/Firebase";

import search_bar_logo from "../../assets/search_bar_2.svg";
import Sidebar from "../Alert/Sidebar";
import SearchBar from "../Alert/SearchBar";

export default function Gallery() {
  const { state } = useLocation();
  const [galleryData, setGalleryData] = useState([]);

  const [discordLinked, setDiscordLinked] = useState(false);
  const [discordCode, setDiscordCode] = useState("");
  const [userImage, setUserImage] = useState("");
  const [userInformation, setUserInformation] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  async function getGallery() {
    const data = await viewGallery();
    const imagesArray = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].length > 0) {
        data[i].forEach((images) => {
          imagesArray.push(images);
        });
      }
    }
    const imagesData = await Promise.all(imagesArray);
    setGalleryData(imagesData);
  }

  useEffect(() => {
    if (user) {
      console.log(user);
      setDiscordLinked(user.discordLinked);
      setDiscordCode(user.discordCode);
      setUserImage(user.image);
      setUserInformation(user.address);
      setEmail(user.email);
      setName(user.username);
    }
    getGallery();
  }, []);

  return (
    <div className="gallery_background" style={{ 
      background: "#212121",
      padding: 0,
      margin: 0,
      overflow: 'hidden',
    }}>
      <Sidebar user={state?.id} />

      <div>
        <SearchBar
          discordLinked={discordLinked}
          discordCode={discordCode}
          userImage={userImage}
          user={userInformation}
          email={email}
          search={false}
        />
      </div>

      <div
        style={{
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <div className="gallery_header">DSK DAOLL-E</div>

        <div className="gallery_description">
          Search and Discover the creations with DSK DAOLL-E massive hub of
          AI-generated artwork and content, along with their prompts.
        </div>

        <div style={{ maxWidth: 500, marginLeft: "auto", marginRight: "auto" }}>
          <input
            placeholder="Search our creation database..."
            className="gallery_search_bar"
          ></input>

          <img
            alt="magnifier"
            style={{ position: "relative", top: -29, right: -425 }}
            src={search_bar_logo}
          ></img>

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: 10,
            }}
          >
            <button className="gallery_search_button">Search</button>

            <button className="gallery_create_button">Create Image</button>
          </div>
        </div>

        <div className="gallery_map" style={{ marginTop: 25 }}>
          {galleryData &&
            galleryData.map((items) => <ViewGalleryImages data={items} />)}
        </div>
      </div>
    </div>
  );

  function ViewGalleryImages(props) {
    const { data } = props;

    return (
      <div>
        <img
          alt="GalleryImage"
          style={{
            border: "white",
            borderStyle: "solid",
            borderRadius: 10,
            borderWidth: 2,
          }}
          height={150}
          src={data}
        ></img>
      </div>
    );
  }
}
