import '../../styles/Dashboard.css';
import '../../styles/SidebarButtons.css'

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { user } from '../../server/Firebase'

import menu_dashboard from '../../assets/menu icons/menu.svg';
import create from '../../assets/menu icons/create.svg';
import dashboard_settings from '../../assets/menu icons/settings.svg';
import chat from '../../assets/menu icons/chat.svg';
import store from '../../assets/menu icons/store.svg';

export default function Sidebar(props) {
  const [userInformation, setUserInformation] = useState("")

  useEffect(() => {
    if(user) {
      setUserInformation(user)
    }
  }, [])

  return (
    <div className="side_bar">
      {/* <div className="side_bar_title">
        Mint Alert
      </div> */}

      <div className="side_bar_select">
        <Link to="/dashboard" state={{ id: props.user }} style={{ textDecoration: 'none' }}>
          <button className="sidebar_button">
            
            <img alt="dashboard" src={menu_dashboard}></img>

          </button>
        </Link>
      </div>

      <div className="side_bar_select" style={{ marginTop: 150 }}>
        <Link to="/gallery" style={{ textDecoration: 'none' }} state={{ id: props.user }}>
          <button className="sidebar_button">

            <img height={25} alt="dashboard" src={create}></img>

          </button>
        </Link>
      </div>

      <div className="side_bar_select" style={{ marginTop: 215 }}>
        <Link to="/chat" style={{ textDecoration: 'none' }} state={{ id: props.user }}>
          <button className="sidebar_button">

            <img height={25} alt="dashboard" src={chat}></img>

          </button>
        </Link>
      </div>

      
      <div className="side_bar_select" style={{ marginTop: 280 }}>
        <Link to="/store" style={{ textDecoration: 'none' }} state={{ id: props.user }}>
          <button className="sidebar_button">

            <img height={25} alt="store" src={store}></img>

          </button>
        </Link>
      </div>

      <div className="side_bar_select" style={{ marginTop: 345 }}>
        <Link to="/settings" style={{ textDecoration: 'none' }} state={{ id: props.user }}>
          <button className="sidebar_button">

            <img alt="dashboard" src={dashboard_settings}></img>

          </button>
        </Link>
      </div>
    </div>
  )
}
