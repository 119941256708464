import "../../styles/App.css";
import "../../styles/Settings.css";

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  uploadImage,
  updateUsername,
  updateEmail,
  getUser,
  getUserImages,
  removeImage,
} from "../../server/Firebase";

import {
  viewGallery,
  user,
  getItems,
  itemShopData,
} from "../../server/Firebase";

import Sidebar from "./Sidebar";
import SearchBar from "./SearchBar";

import profile_upload from '../../assets/dashboard/upload_profile.svg';

export default function Settings() {
  const { state } = useLocation();

  const [currentEmail, setCurrentEmail] = useState("");
  const [currentUsername, setCurrentUsername] = useState("");

  const [imageMap, setImageMap] = useState([]);

  const [userImage, setUserImage] = useState("");
  const [userInformation, setUserInformation] = useState("");
  const [name, setName] = useState("");
  const [image, setNewImage] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [discord, setDiscord] = useState("");
  const [discordCode, setDiscordCode] = useState("");
  const [discordLinked, setDiscordLinked] = useState(false);

  async function getUserInformation(wallet) {
    const userProfile = await getUser(wallet);
    const userImages = await getUserImages(wallet);

    setNewImage(userProfile.image);
    setUsername(userProfile.username);
    setEmail(userProfile.email);
    setDiscordCode(userProfile.discordCode);
    setDiscordLinked(userProfile.discordLinked);
    setDiscord(userProfile.discordUserId);

    if (userImages?.length > 0) {
      setImageMap(userImages);
    } else {
      setImageMap([]);
    }
  }

  async function updateImage(x) {
    const image = x.target.files[0];
    const imageUpload = await uploadImage(image, state.id);

    setNewImage(imageUpload);
    console.log(imageUpload);
  }

  async function usernameUpdate() {
    if (username) {
      const usernameUpdated = await updateUsername(username, state.id);
      alert("Username updated!");
      console.log(usernameUpdated.id);
    } else return;
  }

  async function emailUpdate() {
    if (email) {
      const emailUpdated = await updateEmail(email, state.id);
      alert("Email updated!");
      console.log(emailUpdated);
    } else return;
  }

  async function deleteImage(wallet, id) {
    const imageRemoved = await removeImage(wallet, id);
    if (imageRemoved) {
      await getUserInformation(wallet);
    }
  }

  useEffect(() => {
    if (user) {
      setDiscordLinked(user.discordLinked);
      setDiscordCode(user.discordCode);
      setUserImage(user.image);
      setUserInformation(user.address);
      setEmail(user.email);
      setName(user.username);
    }
    getUserInformation(state?.id);
  }, []);

  return (
    <div
      style={{ color: "white", fontFamily: "MSBEE", overflowY: "auto" }}
      className="App"
    >
      <Sidebar user={state?.id} email={email} />
      <SearchBar
        discordLinked={discordLinked}
        discordCode={discordCode}
        userImage={userImage}
        user={userInformation}
        email={email}
        search={false}
      />

      <div className="settings">
        <div
          style={{
            textAlign: "center",
            fontSize: 22,
          }}
        >
          <span
            style={{ color: "#DF4B4B", fontStyle: "bolder", marginRight: 5 }}
          >
            //{" "}
          </span>{" "}
          Welcome {username}!
        </div>
        <div
          style={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <div
            style={{
              background: "#DF4B4B",
              fontFamily: "MSBEE",
              borderRadius: 15,
              margin: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
            }}
          >
            <div>
                <>
                  <img
                    alt="PFP_IMAGE"
                    style={{ display: "grid", height: 50, borderRadius: 10 }}
                    src={image ? image : "https://i.imgur.com/gUjJBid.jpg"}
                  ></img>
                </>
            </div>
            <div style={{ display: "flex", alignItems: "center", height: 50 }}>
              <form>
                <label
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    // background: "black",
                    // padding: 10,
                    // margin: 10,
                    // borderRadius: 10,
                  }}
                  for="image"
                  type="submit"
                >
                  <input
                    onChange={(x) => updateImage(x)}
                    hidden={true}
                    id="image"
                    type="file"
                  />
                  <img
                    alt="upload_image"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginRight: 10,
                    }}
                    src={profile_upload}
                  ></img>
                </label>
              </form>
              {/* <button id="image" onClick={(x) => updateImage(x.target.value)} style={{ fontFamily: 'MSBEE', color: 'white', background: 'black', padding: 10, border: 'transparent', borderRadius: 10, cursor: 'pointer' }}>Upload</button> */}
            </div>
          </div>

          <div
            style={{
              background: "#DF4B4B",
              fontFamily: "MSBEE",
              borderRadius: 15,
              margin: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 10,
              }}
            >
              <input
                onChange={(x) => setUsername(x.target.value)}
                placeholder={"Username"}
                style={{
                  background: "#212121",
                  borderRadius: 10,
                  border: "transparent",
                  height: "100%",
                  minHeight: "100%",
                  maxHeight: 50,
                  maxWidth: 250,
                  color: "white",
                  fontFamily: "MSBEE",
                  padding: 10,
                  marginRight: 5,
                }}
              ></input>

              <div>
                {username.length > 0 && (
                  <button
                    onClick={() => usernameUpdate()}
                    style={{
                      fontFamily: "MSBEE",
                      color: "white",
                      background: "black",
                      padding: 10,
                      border: "transparent",
                      borderRadius: 10,
                      marginRight: "auto",
                      marginLeft: "auto",
                      cursor: "pointer",
                    }}
                  >
                    Save
                  </button>
                )}

                {username.length === 0 && (
                  <button
                    disabled
                    onClick={() => usernameUpdate()}
                    style={{
                      fontFamily: "MSBEE",
                      color: "white",
                      background: "black",
                      padding: 10,
                      border: "transparent",
                      borderRadius: 10,
                      marginRight: "auto",
                      marginLeft: "auto",
                      cursor: "pointer",
                    }}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
            <div
              style={{
                textAlign: "right",
                marginRight: 10,
                padding: 5,
              }}
            >
              {username ? username : "No username saved"}
            </div>
          </div>

          <div
            style={{
              background: "#DF4B4B",
              fontFamily: "MSBEE",
              padding: 5,
              borderRadius: 15,
              margin: 10,
            }}
          >
            <div
              style={{
                background: "#DF4B4B",
                fontFamily: "MSBEE",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 10,
              }}
            >
              <input
                onChange={(x) => setEmail(x.target.value)}
                placeholder={"Email"}
                style={{
                  background: "#212121",
                  borderRadius: 10,
                  border: "transparent",
                  height: "100%",
                  minHeight: "100%",
                  maxHeight: 50,
                  maxWidth: 250,
                  color: "white",
                  fontFamily: "MSBEE",
                  padding: 10,
                  marginRight: 5,
                }}
              ></input>

              <div>
                {email.length > 0 && (
                  <button
                    onClick={() => emailUpdate()}
                    style={{
                      fontFamily: "MSBEE",
                      color: "white",
                      background: "black",
                      padding: 10,
                      border: "transparent",
                      borderRadius: 10,
                      marginRight: "auto",
                      marginLeft: "auto",
                      cursor: "pointer",
                    }}
                  >
                    Save
                  </button>
                )}

                {email.length === 0 && (
                  <button
                    disabled
                    onClick={() => emailUpdate()}
                    style={{
                      fontFamily: "MSBEE",
                      color: "white",
                      background: "black",
                      padding: 10,
                      border: "transparent",
                      borderRadius: 10,
                      marginRight: "auto",
                      marginLeft: "auto",
                      cursor: "pointer",
                    }}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
            <div
              style={{
                textAlign: "right",
                marginRight: 10,
              }}
            >
              {email ? email : "No email saved"}
            </div>
          </div>

          <div
            style={{
              background: "#DF4B4B",
              fontFamily: "MSBEE",
              borderRadius: 15,
              margin: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
            }}
          >
            {discordLinked === false && <div>{discordCode}</div>}

            {discordLinked === true && (
              <div>Discord: {discord.slice(0, 8)}...</div>
            )}

            {discordLinked === false && <div>⚠️ Not linked</div>}

            {discordLinked === true && <div>✅ Linked</div>}
          </div>

          {discordLinked === false && (
            <div
              style={{
                fontSize: 13,
                textAlign: "center",
                background: "red",
                borderRadius: 15,
                margin: 10,
                padding: 10,
              }}
            >
              Use command /link in the DSKDAO discord, copy the code above and
              paste it to link your account.
            </div>
          )}
        </div>

        <div style={{ margin: 10 }}>
          <div className="itemMap">
            {imageMap &&
              imageMap.map((items, index) => (
                <div>
                  <ViewItems data={[items, index]} key={index} />
                </div>
              ))}

            {imageMap && imageMap.length === 0 && (
              <div style={{ textAlign: "center" }}>
                You have not saved any images from DAOLL-E.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  function ViewItems(props) {
    console.log(props);

    const image = props.data[0];
    const image_id = props.data[1];

    return (
      <div style={{ display: "grid", width: 150 }}>
        <img
          alt="DAOLL-E IMG"
          onClick={() => window.open(image)}
          style={{
            height: 150,
            cursor: "pointer",
            borderStyle: "solid",
            borderWidth: 1,
            boderColor: "white",
            borderRadius: 15,
          }}
          src={image}
        ></img>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 3,
          }}
        >
          <div>{image_id}</div>
          <button
            style={{
              fontFamily: "MSBEE",
              textTransform: "uppercase",
              background: "#DF4B4B",
              borderRadius: 5,
              color: "white",
              border: "transparent",
              cursor: "pointer",
            }}
            onClick={() => deleteImage(state.id, image)}
          >
            Delete
          </button>
        </div>
      </div>
    );
  }
}
