import {
  initializeApp
} from "firebase/app";

import {
  collection,
  addDoc,
  setDoc,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  initializeFirestore,
  updateDoc,
  collectionGroup,
  where,
  query,
  getFirestore,
  FieldValue,
  arrayUnion,
  arrayRemove,
  serverTimestamp,
  increment,
  orderBy
} from "firebase/firestore";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage';

import { v4 as uuidv4 } from 'uuid';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

const app = initializeApp(firebaseConfig);

const database = initializeFirestore(app, {
  experimentalForceLongPolling: true, // this line
  useFetchStreams: false, // and this line
})

// const database = getFirestore()

export var user;
export var itemShopData;
export var gallery;
export var wlProjects;
export var vouchersData;
export var raffleItems;

export async function readProjects() {
  const projects = collection(database, 'projects');
  const queryProjects = await getDocs(projects);

  // const projectsData = collection(database, 'projects');
  // const q = query(projectsData, where("status", "==", "pending"));
  // const querySnapshot = await getDocs(q);

  // querySnapshot.forEach((doc) => {
  //   getUsersAppointment(doc.id);
  // });

  // async function getUsersAppointment(doctorId) {
  //   const appointments = query(collectionGroup(database, 'users'));
  //   const querySnapshot = await getDocs(appointments);
  //   querySnapshot.forEach((doc) => {
  //     console.log(doc.id, ' => ', doc.data());
  //   });
  // }

  const array = [];
  queryProjects.forEach(async (data) => {

    const project = data._document.data.value.mapValue.fields;
    const id = data.id;

    const projectDetails = {
      project: project,
      id: id
    }

    array.push(projectDetails)
  })

  const arrayData = await Promise.all(array);
  wlProjects = arrayData;
  return arrayData;
}

export async function addProject(c, d, n, r, s, w, wl, eth, usd, day, month, mA, wA, t, dayFormat) {
  const date = new Date(t)

  const item = {
    contract: c,
    discord: d,
    name: n,
    review: r,
    supply: Number(s),
    website: w,
    wldate: wl,
    wleth: Number(eth),
    wlusd: Number(usd),
    day: Number(day),
    month: month,
    mintAmount: Number(mA),
    wlAmount: Number(wA),
    timestamp: date,
    dayFormat: dayFormat
  }

  const addProject = await addDoc(collection(database, "projects"), item);

  return addProject;
}

export async function deleteProject(id) {
  const addProject = await deleteDoc(doc(collection(database, "projects"), id));

  return addProject;
}

export async function getUser(wallet) {
  try {
    const userProfile = doc(database, "users", `${wallet}`);
    const docSnap = await getDoc(userProfile);
    const id = uuidv4();

    if (docSnap.exists()) {
      user = docSnap.data();
      return docSnap.data();
    } else {
      return await addUser(wallet, id);
    }
  } catch (err) {
    console.log(err)
  }
}

async function addUser(wallet, id) {
  if (wallet.code === 4001) return;
  if (wallet.code === -32603) return;
  await setDoc(doc(database, "users", `${wallet}`), {
    address: wallet,
    username: `User-${id.slice(0, 5)}`,
    profileImage: '',
    discordUserId: '',
    discordCode: id.slice(0, 8),
    email: '',
    images: [],
    discordLinked: false,
    tickets: []
  });
  return await getUser(wallet);
}

export async function uploadImage(file, wallet) {
  console.log(file)
  return new Promise((resolve, reject) => {
    const storage = getStorage();

    const storageRef = ref(storage, `${wallet}.jpg`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');

      },
      (error) => {

      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          const imageUpload = doc(database, "users", `${wallet}`);

          updateDoc(imageUpload, {
            image: downloadURL
          });

          console.log(downloadURL);

          resolve(downloadURL);
        });
      }
    );
  })
};

export async function updateUsername(username, wallet) {
  const usernameUpdate = doc(database, "users", `${wallet}`);

  await updateDoc(usernameUpdate, {
    username: username.toString()
  });

  return usernameUpdate;
}

export async function updateEmail(email, wallet) {
  const emailUpdate = doc(database, "users", `${wallet}`);

  await updateDoc(emailUpdate, {
    email: email
  });

  return emailUpdate;
}

export async function getUserImages() {
  return user.images;
  // const userProfile = doc(database, "users", `${wallet}`);
  // const docSnap = await getDoc(userProfile);

  // if(docSnap.exists()) {
  //   return docSnap.data().images;
  // } else {
  //   console.log("No such document was found.")
  // }
}

export async function removeImage(wallet, id) {
  try {
    const userImages = doc(database, "users", `${wallet}`);

    await updateDoc(userImages, {
      images: arrayRemove(id)
    });

    return true;
  } catch (err) {
    console.log(err)
  }
}

export async function viewGallery() {
  const projects = collection(database, 'users');
  const images = await getDocs(projects);

  let galleryArray = [];

  images.forEach((images) => {
    const imageData = images.data();
    galleryArray.push(imageData.images);
  });

  const galleryImages = await Promise.all(galleryArray);
  gallery = galleryImages;
  return galleryImages;
}

export async function getChat() {
  const chatMessages = collection(database, 'chat');
  const chats = await getDocs(chatMessages);

  let chatArray = []

  chats.forEach((dataC) => {
    chatArray.push(dataC.data())
  })

  const chatData = await Promise.all(chatArray)
  return chatData;
}

export async function getItems() {
  const itemShop = collection(database, 'itemShop');
  const items = await getDocs(itemShop);

  let itemsArray = [];

  items.forEach((itemsData) => {
    itemsArray.push(itemsData.data())
  });

  const itemsData = await Promise.all(itemsArray);
  itemShopData = itemsData;

  return itemsData;
}

export async function addItems(name) {
  // const date = new Date(t)

  const item = {
    name: name
  }

  const addProject = await addDoc(collection(database, "shopItems"), item);

  return addProject;

}

export async function addRaffleItems(raffleItem) {
  console.log("Raffle item amount")
  console.log(raffleItem.amount)

  const item = {
    name: raffleItem.name,
    active: true,
    amount: Number(raffleItem.amount),
    contractAddress: raffleItem.contractAddress,
    costAmount: Number(raffleItem.costAmount),
    createdAt: serverTimestamp(),
    ended: false,
    image: raffleItem.image,
    itemType: raffleItem.itemType,
    maxPerWallet: Number(raffleItem.maxPerWallet),
    puchased: 0,
    tokenId: Number(raffleItem.tokenId),
    totalSupply: Number(raffleItem.totalSupply),
    etherscanLink: raffleItem?.etherscanLink ? raffleItem?.etherscanLink : "",
    twitterURL: raffleItem?.twitterURL ? raffleItem?.twitterURL : "",
    websiteLink: raffleItem?.websiteLink ? raffleItem?.websiteLink : "",
    openseaLink: raffleItem?.openseaLink ? raffleItem?.openseaLink : ""
  }
  const newRaffleItem = doc(collection(database, "raffleItems"));
  const addProject = await setDoc(newRaffleItem, item);
  return addProject;
}

export async function burnTickets(name, user, tickets, userBalance, ticketEntries) {
  if (userBalance <= 0) return alert("Your balance not sufficient!")
  if (userBalance < ticketEntries) return alert("Tickets cost more than you have buddy!");
  const userData = doc(database, "users", `${user}`);

  const rafflesArray = collection(database, "raffleItems");
  const raffles = query(rafflesArray, where("name", "==", name));
  const queryRaffles = await getDocs(raffles);

  const raffleStats = collection(database, "raffleStats");
  const queryRaffleStats = await getDocs(raffleStats);

  // Update Raffle Document
  queryRaffles.forEach(async (rafflesData) => {
    if (rafflesData.data().name === name) {
      const raffleItem = doc(database, "raffleItems", `${rafflesData.id}`);
      await updateDoc(raffleItem, {
        purchased: increment(ticketEntries),
      });
    }
  });

  // Update Raffle Stats Document
  queryRaffleStats.forEach(async (raffles) => {
    const raffleStatsUpdate = doc(database, "raffleStats", `${raffles.id}`);
    await updateDoc(raffleStatsUpdate, {
      ticketsBurned: increment(tickets),
      vouchersTotal: increment(1)
    })
  })

  const date = new Date().toISOString();
  const ticket = {
    date: date,
    size: ticketEntries,
    item: name
  };

  // Update User Balance
  await updateDoc(userData, {
    balance: increment(-Number(tickets)),
    tickets: arrayUnion(ticket)
  });

  return true;
}

export async function getVouchers() {
  // Get user vouchers instead of all vouchers
  const raffleStats = collection(database, "raffleStats");
  const stats = await getDocs(raffleStats);

  let statsArray = [];

  stats.forEach((data) => {
    console.log(data.data())
    statsArray.push(data.data())
  })

  const rafflesStatsReturn = await Promise.all(statsArray)
  return rafflesStatsReturn;
}

export async function raffleWinners() {
  const raffleWinners = collection(database, 'rafflesEnded');
  const winners = await getDocs(raffleWinners);

  let winnerArray = [];

  winners.forEach((winner) => {
    // console.log(winner)
    winnerArray.push(winner.data())
  })

  const winnersData = await Promise.all(winnerArray);
  return winnersData;
}

export async function saveWinner(name, address, discordWinnerId, ticketsLength) {
  await addDoc(collection(database, "rafflesEnded"), {
    discordWinnerId: discordWinnerId,
    name: name,
    tickets: ticketsLength,
    winnerWalletAddress: address,
    winner: address
  });
}

export async function getRaffleItems() {
  const rafflesArray = collection(database, "raffleItems");
  const raffles = query(rafflesArray);
  const queryRaffles = await getDocs(raffles);

  let allRaffles = [];

  queryRaffles.forEach((raffles) => {
    allRaffles.push(raffles.data())
  });

  raffleItems = allRaffles;
  const raffleItemsFound = await Promise.all(allRaffles);
  return raffleItemsFound;
}