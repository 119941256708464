import "../../styles/Marketplace/Marketplace.css";

import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import SearchBar from "./SearchBar";
import numeral from "numeral";

import { user, getItems, itemShopData } from "../../server/Firebase";

export default function Marketplace() {
  const [discordLinked, setDiscordLinked] = useState(false);
  const [discordCode, setDiscordCode] = useState("");
  const [userImage, setUserImage] = useState("");
  const [userInformation, setUserInformation] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  // Items
  const [items, setItems] = useState([]);

  async function search(item) {
    console.log(item);
  }

  async function getItemsArray() {
    const itemsArray = await getItems();
    setItems(itemsArray);
  }

  async function searchItems(itemName) {
    const itemsServer = await itemShopData;

    console.log(itemName)
    console.log(itemsServer)
  }

  useEffect(() => {
    if (user) {
      console.log(user);
      setDiscordLinked(user.discordLinked);
      setDiscordCode(user.discordCode);
      setUserImage(user.image);
      setUserInformation(user.address);
      setEmail(user.email);
      setName(user.username);
    }
    getItemsArray();
    searchItems();
  }, []);

  return (
    <div>
      <Sidebar />

      <div className="marketplace_background">
        {userInformation && (
          <SearchBar
            discordLinked={discordLinked}
            discordCode={discordCode}
            userImage={userImage}
            user={userInformation}
            email={email}
            name={(x) => searchItems(x)}
            search={true}
          />
        )}

        {!userInformation && <div style={{ height: 50 }}></div>}

        <div className="featured_item">
          {items &&
            items.slice(0, 1).map((items) => (
              <div className="featured">
                <div>
                  <img
                    alt=""
                    style={{
                      maxHeight: 229,
                      maxWidth: 456,
                      border: "3px white solid",
                      borderRadius: 15,
                    }}
                    src={items.image}
                  ></img>
                </div>

                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <div>Item</div>

                  <div style={{ fontSize: 24 }}>
                    {items.name}

                    <div
                        style={{
                          background: "#313131",
                          padding: 5,
                          borderRadius: 20,
                          fontSize: 13,
                          maxHeight: 25,
                          maxWidth: 150,
                          textAlign: 'left',
                          marginTop: 3
                        }}
                    >
                      {items.itemType}
                    </div>
                  </div>

                  <div>Quantity</div>

                  <div style={{ fontSize: 24, height: 45 }}>
                    {numeral(items.totalSupply - items.purchased).format("0,0")}{" "}
                    / {numeral(items.totalSupply).format("0,0")}
                  </div>

                  <button className="buy_a_ticket">Buy a ticket</button>
                </div>

                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "grid",
                      textAlign: "center",
                      fontSize: 24,
                    }}
                  >
                    Requirements
                  </div>

                  <div
                    className="requirement_map"
                    style={{
                      minHeight: 50,
                      marginTop: 25.5,
                      marginBottom: 25.5,
                    }}
                  >
                    {items.requirements &&
                      items.requirements.map((requirements) => (
                        <div
                          style={{
                            background: "#313131",
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 20,
                            fontSize: 13,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {requirements}
                        </div>
                      ))}
                  </div>

                  <button
                    style={{ background: "#515151" }}
                    className="buy_a_ticket"
                  >
                    Details
                  </button>
                </div>
              </div>
            ))}
        </div>

        <div
          style={{
            marginTop: 25,
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            marginRight: 20,
          }}
        >
          <button
            style={{ minWidth: 100, maxWidth: 150, fontSize: 13 }}
            className="buy_a_ticket"
          >
            All
          </button>
          <button
            style={{ minWidth: 100, maxWidth: 150, fontSize: 13 }}
            className="buy_a_ticket"
          >
            NFT'S
          </button>
          <button
            style={{ minWidth: 100, maxWidth: 200, fontSize: 13 }}
            className="buy_a_ticket"
          >
            Physical Collectibles
          </button>
          <button
            style={{ minWidth: 100, maxWidth: 150, fontSize: 13 }}
            className="buy_a_ticket"
          >
            Items
          </button>
        </div>

        <div className="items_map" style={{ marginTop: 25 }}>
          {items &&
            items.slice(1, items.length).map((items) => (
              <>
                <div className="items_card">
                  <div>
                    <img
                      alt=""
                      style={{ width: "100%", minHeight: 300, maxHeight: 300 }}
                      src={items.image}
                    ></img>
                  </div>

                  <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                    <div>Item</div>

                    <div style={{ fontSize: 24 }}>
                      {items.name}
                      <div
                        style={{
                          background: "#313131",
                          padding: 5,
                          borderRadius: 20,
                          fontSize: 13,
                          maxHeight: 25,
                          maxWidth: 150,
                          textAlign: 'left',
                          marginTop: 3
                        }}
                      >
                        {items.itemType}
                      </div>
                    </div>

                    <div>Quantity</div>

                    <div style={{ fontSize: 24, height: 45 }}>
                      {numeral(items.totalSupply - items.purchased).format(
                        "0,0"
                      )}{" "}
                      / {numeral(items.totalSupply).format("0,0")}
                    </div>
                  </div>

                  <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                    <div>Requirements</div>

                    <div
                      className="requirement_map"
                      style={{
                        minHeight: 80,
                        marginTop: 10.5,
                        marginBottom: 10.5,
                      }}
                    >
                      {items.requirements &&
                        items.requirements.map((requirements) => (
                          <div
                            style={{
                              background: "#313131",
                              paddingLeft: 10,
                              paddingRight: 10,
                              borderRadius: 20,
                              fontSize: 13,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              maxHeight: 25,
                            }}
                          >
                            {requirements}
                          </div>
                        ))}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "auto",
                    }}
                  >
                    <button
                      style={{
                        marginRight: "auto",
                        marginLeft: "auto",
                        background: "#515151",
                        margin: 5,
                      }}
                      className="buy_a_ticket"
                    >
                      Details
                    </button>

                    <button
                      style={{
                        marginRight: "auto",
                        marginLeft: "auto",
                        margin: 5,
                      }}
                      className="buy_a_ticket"
                    >
                      Buy a ticket
                    </button>
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
}
