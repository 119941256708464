import "../../styles/Raffles/Cards.css";
import "../../styles/Raffles/Raffles.css";

import React, { useState, useEffect } from "react";

import ticketIcon from "../../assets/raffles/ticket-icon.svg";
import openseaLogo from "../../assets/raffles/opensea_logo.svg";
import twitterLogo from "../../assets/raffles/twitter_logo.svg";
import etherscanLogo from "../../assets/raffles/etherscan_logo.svg";

import { getRaffleItems, raffleItems } from "../../server/Firebase";

export default function DisplayCards(props) {
  const [rafflesMap, setRafflesMap] = useState([]);

  async function burnTickets(item) {
    await props.setBurn(true);
    await props.setItemToBurn(item[0]);
    await props.setItemImage(item[1]);
    await props.setItemPrice(item[2]);
    await props.setMaxPerWallet(item[3]);
  }

  useEffect(() => {
    async function getItems() {
      const checkRaffleItems = await raffleItems;
      if (!checkRaffleItems) {
        const items = await getRaffleItems();
        setRafflesMap(items);
      }
    }
    getItems();
  }, []);

  return (
    <div>
      <div>
        <div
          style={{
            color: "white",
            textAlign: "left",
            marginLeft: 10,
            fontSize: "1.5rem",
            fontFamily: "MSBee",
          }}
        >
          Active Raffles
        </div>
        <hr
          style={{
            width: "250px",
            color: "#df4b4b",
            margin: "0.53rem",
            marginLeft: "10px",
            marginRight: "auto",
            marginBottom: 15,
          }}
        ></hr>
        <div className="card_map">
          {rafflesMap &&
            rafflesMap.map((raffles) => (
              <>
                {raffles.active === true && (
                  <div className="raffle_card">
                    <img className="raffle_image" src={raffles.image} alt="" />
                    <Title raffles={raffles} />
                    <RafflesPrices rafflesPrice={raffles} />

                    <SocialButtons raffles={raffles} />

                    {raffles.purchased >= raffles.totalSupply ? (
                      <div style={{ padding: 5, color: "white" }}>A winner will be chosen soon.</div>
                    ) : (
                      <BuyButton raffles={raffles} />
                    )}
                  </div>
                )}
              </>
            ))}
        </div>

        <div
          style={{
            color: "white",
            textAlign: "left",
            marginLeft: 10,
            fontSize: "1.5rem",
            fontFamily: "MSBee",
            marginTop: "1.25rem",
          }}
        >
          Completed Raffles
        </div>
        <hr
          style={{
            width: "250px",
            color: "#df4b4b",
            margin: "0.53rem",
            marginLeft: "10px",
            marginRight: "auto",
            marginBottom: 15,
          }}
        ></hr>

        <div className="card_map">
          {rafflesMap &&
            rafflesMap.map((raffles) => (
              <>
                {raffles.active === false && (
                  <>
                    <div className="card_map_2">
                      <div className="raffle_card">
                        <img
                          className="raffle_image"
                          src={raffles.image}
                          alt=""
                        />
                        <Title raffles={raffles} />
                        <RafflesPrices rafflesPrice={raffles} />

                        <hr
                          style={{ maxHeight: "0px" }}
                          height={"1px"}
                          width={"100%"}
                        ></hr>

                        <div className="raffle_social_buttons">
                          {raffles.twitterURL && (
                            <button
                              onClick={() => window.open(raffles.twitterURL)}
                              className="social_button"
                            >
                              <img alt="" height={15} src={twitterLogo}></img>
                            </button>
                          )}

                          {raffles.openseaLink && (
                            <button
                              onClick={() => window.open(raffles.openseaLink)}
                              className="social_button"
                            >
                              <img alt="" height={15} src={openseaLogo}></img>
                            </button>
                          )}

                          {raffles.websiteLink && (
                            <button
                              onClick={() => window.open(raffles.websiteLink)}
                              className="social_button"
                            >
                              WEB
                            </button>
                          )}

                          {raffles.etherscanLink && (
                            <button
                              onClick={() => window.open(raffles.etherscanLink)}
                              className="social_button"
                            >
                              <img alt="" height={15} src={etherscanLogo}></img>
                            </button>
                          )}
                        </div>

                        <div>
                          <button className="raffle_buy_button">
                            WINNER
                            <div
                              style={{
                                fontFamily: "MSBee",
                              }}
                            >
                              {raffles.winnerAddress.slice(0, 10)}...
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ))}
        </div>
      </div>
    </div>
  );

  function Title(props) {
    return (
      <>
        <div className="raffle_type">
          <img
            alt="raffle_ticket"
            style={{ marginRight: 6 }}
            height={15}
            src={ticketIcon}
          ></img>{" "}
          {props.raffles.itemType}
        </div>

        <div className="raffle_title">{props.raffles.name}</div>
      </>
    );
  }

  function SocialButtons(props) {
    return (
      <>
        <div
          style={{
            padding: 10,
            color: "white",
            borderRadius: 10,
          }}
        >
          <div className="raffle_social_buttons">
            {props.raffles.twitterURL && (
              <button
                onClick={() => window.open(props.raffles.twitterURL)}
                className="social_button"
              >
                <img alt="" height={15} src={twitterLogo}></img>
              </button>
            )}

            {props.raffles.openseaLink && (
              <button
                onClick={() => window.open(props.raffles.openseaLink)}
                className="social_button"
              >
                <img alt="" height={15} src={openseaLogo}></img>
              </button>
            )}

            {props.raffles.websiteLink && (
              <button
                onClick={() => window.open(props.raffles.websiteLink)}
                className="social_button"
              >
                WEB
              </button>
            )}

            {props.raffles.etherscanLink && (
              <button
                onClick={() => window.open(props.raffles.etherscanLink)}
                className="social_button"
              >
                <img alt="" height={15} src={etherscanLogo}></img>
              </button>
            )}
          </div>
        </div>
      </>
    );
  }

  function RafflesPrices(props) {
    return (
      <>
        <div className="raffle_price">
          <div className="ticket_amount">
            <img
              alt="ticket_icon"
              className="ticket_icon_background"
              height={13}
              src={ticketIcon}
            ></img>

            <div>
              {props.rafflesPrice.costAmount} / {props.rafflesPrice.costAmount}
            </div>
          </div>

          <div className="ticket_amount">
            {(props.rafflesPrice.totalSupply
              ? props.rafflesPrice.totalSupply
              : 0) -
              (props.rafflesPrice?.purchased
                ? props.rafflesPrice.purchased
                : 0)}{" "}
            / {props.rafflesPrice.totalSupply}
          </div>
        </div>
      </>
    );
  }

  function BuyButton(props) {
    return (
      <div>
        <button
          onClick={() =>
            burnTickets([
              props.raffles.name,
              props.raffles.image,
              props.raffles.costAmount,
              props.raffles.maxPerWallet,
            ])
          }
          className="raffle_buy_button"
        >
          Enter Raffle
        </button>
      </div>
    );
  }
}
