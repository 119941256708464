import '../../styles/App.css';
import '../../styles/Home/Home.css';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// import azukiplacement from '../../assets/home page/background.mp4';
import character from '../../assets/home page/character.png';

function Home() {
  const [warning, setWarning] = useState(false);

  return (
    <div className="connect">
      <img
        alt=""
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          zIndex: 0,
          display: "grid",
        }}
        height={500}
        src={character}
      ></img>

      {!warning && (
        <div className="login_div">
          <div className="title_letters">
            <span
              style={{ color: "#DF4B4B", fontStyle: "bolder", marginRight: 5 }}
            >
              {"//"}{" "}
            </span>{" "}
            CAUTION
          </div>

          <div
            style={{
              fontSize: 15,
              display: "grid",
              textAlign: 'center',
              padding: 15
            }}
            className="title_letters"
          >
            <span
              style={{ color: "#DF4B4B", fontStyle: "bolder", marginRight: 5 }}
            >
              All tickets will be reset
            </span>{" "}
            after BETA phase.
          </div>

          <button onClick={() => setWarning(!warning)} className="login_button">ACCEPT</button>
        </div>
      )}

      {warning && (
        <div className="login_div">
          <div className="title_letters">
            <span
              style={{ color: "#DF4B4B", fontStyle: "bolder", marginRight: 5 }}
            >
              {"//"}{" "}
            </span>{" "}
            DSKDAO
          </div>

          <div
            style={{
              fontSize: 15,
            }}
            className="title_letters"
          >
            <span
              style={{ color: "#DF4B4B", fontStyle: "bolder", marginRight: 5 }}
            >
              You're
            </span>{" "}
            early.
          </div>

          {/* <Link to="/dashboard">
                  <button style={{ background: "#DF4B4B", border: "transparent", padding: 10, borderRadius: 10, fontFamily: "MSBEE", textTransform: 'uppercase', color: 'white', cursor: 'pointer' }}>
                    Visit Dashboard
                  </button>
                </Link> */}

          <Link
            style={{
              textDecoration: "none",
              marginTop: 20,
            }}
            to="/raffles"
          >
            <button className="login_button">View Raffles</button>
          </Link>
        </div>
      )}

      {/* <video loop={true} muted={true} autoPlay={true} controls={false} className="background view_video">
        <source type="video/mp4" className="" src={azukiplacement}></source>
      </video> */}
    </div>
  );
}

export default Home;