import "../../styles/Dashboard.css";
import '../../styles/Raffles/Cards.css';

import React, { useState } from 'react';
import { ConnectMetamask } from '../../context/WalletLogin';

import { addRaffleItems } from '../../server/Firebase';

export default function AddRaffleItems() {

  const [name, setName] = useState("");
  const [amount, setamount] = useState(0);
  const [contractAddress, setcontractAddress] = useState("");
  const [image, setImage] = useState("");
  const [itemType, setitemType] = useState("");
  const [maxPerWallet, setmaxPerWallet] = useState(0);
  const [tokenId, settokenId] = useState(0);
  const [totalSupply, settotalSupply] = useState(0);
  const [confirmation, setConfirmation] = useState(false);
  const [costAmount, setCostAmount] = useState(0);
  const [etherscan, setEtherscanLink] = useState("");
  const [openseaLink, setOpenseaLink] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  async function addRaffleItem() {
    const raffleItem = {
      name: name,
      amount: amount,
      contractAddress: contractAddress,
      image: image,
      itemType: itemType,
      maxPerWallet: maxPerWallet,
      tokenId: tokenId,
      totalSupply: totalSupply,
      costAmount: costAmount,
      etherscanLink: etherscan,
      openseaLink: openseaLink,
      websiteLink: websiteLink,
      twitterLink: twitterLink
    };

    console.log(raffleItem)

    const letAdd = await addRaffleItems(raffleItem);
    alert("Item added to the raffle shop.");
    console.log(letAdd);

    let itemAddedEmbed = {
      content: "<@&1109469028868702208>",
      embeds: [
        {
          type: "rich",
          title: `🏪 New Raffle Item `,
          description: "",
          color: 0xdf4b4b,
          fields: [
            {
              name: `Item Details`,
              value: `❇️  ╌ \t${name} \n  ├ 🎫 **${costAmount}** **Tickets** to enter\n  ├ 🧾 **${totalSupply}** total entries\n  ├ 🎲 **${maxPerWallet} entries** / user`,
              inline: true
            }
          ],
          url: `https://www.dskdao.com`,
          image: {
            "url": image,
            "proxy_url": image,
            "height": 250,
            "width": 250
          },
        }
      ],
    };

    fetch("https://discord.com/api/webhooks/1111797068432949329/mZZ4JZzMxjwmgHLgOHP29hClkShbkOxAf9Nx-RolfqbybbLBcgR1eW8UySY9VheNULGf", {
      method: "POST",
      body: JSON.stringify(itemAddedEmbed),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      console.log("Burn successful!")
    }).catch((err) => {
      console.log(err)
    })

    setName("");
    setamount(0);
    setcontractAddress("");
    setImage("");
    setitemType("");
    setmaxPerWallet("");
    settokenId("");
    settotalSupply(0);
    setCostAmount(0);
  }

  async function checkAdmin() {
    const user = await ConnectMetamask()

    if (user === "0x0567c3ff9dc5ad1094db05381b51abfc5239f781") {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
      alert("Admin not authorized!")
      return;
    }
  }

  return (
    <div
      stlye={{
        display: "grid",
        alignItems: "left",
        justifyContent: "left",
        maxWidth: 500,
        width: "90%",
        margin: 20,
      }}
    >

      {loggedIn === false && (
        <div style={{
          display: 'grid',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          marginTop: 'auto',
          marginBottom: 'auto',
          marginLeft: 'auto',
          marginRight: 'auto',
          height: '100vh',
          textAlign: 'center',
          fontFamily: "MSBEE",
          fontSize: 22,
          textTransform: 'uppercase'
        }}>
          Confirm admin

          <div>
            <button
              style={{
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
              className="general_buttons" onClick={() => checkAdmin()}>
              Connect
            </button>
          </div>
        </div>
      )}


      {confirmation === false && loggedIn === true && (
        <>
          <div
            style={{ fontSize: 22, fontFamily: "MSBEE", textAlign: "center" }}
          >
            Add Raffle Item
          </div>

          <div className="add_an_item">
            <div>Image Link</div>
            <input
              className="add_item_input"
              onChange={(x) =>
                setImage(x.currentTarget.value)
              }
              type="text"
            ></input>
          </div>

          <div className="add_an_item">
            <div>Name</div>
            <input
              className="add_item_input"
              onChange={(x) =>
                setName(x.currentTarget.value)
              }
              type="text"
            ></input>
          </div>

          <div className="add_an_item">
            <div>Amount</div>
            <input
              className="add_item_input"
              onChange={(x) =>
                setamount(x.currentTarget.value)
              }
              type="number"
            ></input>
          </div>

          <div className="add_an_item">
            <div>Contract Address</div>
            <input
              className="add_item_input"
              onChange={(x) =>
                setcontractAddress(x.currentTarget.value)
              }
              type="text"
            ></input>
          </div>

          <div className="add_an_item">
            <div>Cost Amount</div>
            <input
              className="add_item_input"
              onChange={(x) =>
                setCostAmount(x.currentTarget.value)
              }
              type="number"
            ></input>
          </div>

          <div className="add_an_item">
            <div>Item Type</div>
            <input
              className="add_item_input"
              onChange={(x) =>
                setitemType(x.currentTarget.value)
              }
              type="text"
            ></input>
          </div>

          <div className="add_an_item">
            <div>Max Per Wallet</div>
            <input
              className="add_item_input"
              onChange={(x) =>
                setmaxPerWallet(x.currentTarget.value)
              }
              type="number"
            ></input>
          </div>

          <div className="add_an_item">
            <div>Token ID</div>
            <input
              className="add_item_input"
              onChange={(x) =>
                settokenId(x.currentTarget.value)
              }
              type="number"
            ></input>
          </div>

          <div className="add_an_item">
            <div>Total Supply</div>
            <input
              className="add_item_input"
              onChange={(x) =>
                settotalSupply(x.currentTarget.value)
              }
              type="number"
            ></input>
          </div>

          <div className="add_an_item">
            <div>Etherscan Link</div>
            <input
              className="add_item_input"
              onChange={(x) =>
                setEtherscanLink(x.currentTarget.value)
              }
              type="text"
            ></input>
          </div>

          <div className="add_an_item">
            <div>Opensea Link</div>
            <input
              className="add_item_input"
              onChange={(x) =>
                setOpenseaLink(x.currentTarget.value)
              }
              type="text"
            ></input>
          </div>

          <div className="add_an_item">
            <div>Website Link</div>
            <input
              className="add_item_input"
              onChange={(x) =>
                setWebsiteLink(x.currentTarget.value)
              }
              type="text"
            ></input>
          </div>

          <div className="add_an_item">
            <div>Twitter Link</div>
            <input
              className="add_item_input"
              onChange={(x) =>
                setTwitterLink(x.currentTarget.value)
              }
              type="text"
            ></input>
          </div>

          <div
            style={{
              textAlign: "center",
              padding: 10,
              display: "grid",
            }}
          >
            <button
              onClick={() => setConfirmation(!confirmation)}
              className="general_buttons"
            >
              Add Item To Shop
            </button>
          </div>
        </>
      )}

      {confirmation === true && loggedIn === true && (
        <div>
          <div
            style={{ fontSize: 22, fontFamily: "MSBEE", textAlign: "center", display: 'grid' }}
          >
            Confirm Details
          </div>

          <div className="confirmation_text">
            Name: {name}</div>

          <img height={125} alt="" src={image}></img>

          <div className="confirmation_text">
            Amount: {amount}</div>

          <div className="confirmation_text">
            Contract: {contractAddress.slice(0, 12)}...</div>

          <div className="confirmation_text">
            Ticket Cost: {costAmount}</div>

          <div className="confirmation_text">
            Item Type: {itemType}</div>

          <div className="confirmation_text">
            Max Tickets / Wallet: {maxPerWallet}</div>

          <div className="confirmation_text">
            Token ID: {tokenId}</div>

          <div className="confirmation_text">
            Total Supply: {totalSupply}</div>

          <div>
            <button onClick={() => addRaffleItem()}>
              save
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
